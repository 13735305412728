import React, { useContext } from 'react'
import { DeviceWidthContext } from '../context/deviceWidthContext';
import { HashLink as Link } from 'react-router-hash-link';

export default function Footer() {

  const { deviceWidth } = useContext(DeviceWidthContext) 

  return (
    <div className='footer'>
       <img className="logo_action-rse" src="https://action-rse.fr/Assets/action_rse_logo.png" alt="" />
      <div className="financeurs">
        {deviceWidth > 800 ?
        <img src="https://action-rse.fr/Assets/bandeau_RSE_NFC.jpg" alt="" />
        :
        <img src="https://action-rse.fr/Assets/bandeauFinancerusActionRSE.PNG" alt="" />
        }
      </div>
      <Link to="/mentions_legales">Mentions légales</Link>
    </div>
  )
}
