import * as firebase from "firebase/app"
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyB8QnUqi8cBvl4TEZkVGHu4ZjBeMI8LW94",
  authDomain: "action-rse-c3198.firebaseapp.com",
  projectId: "action-rse-c3198",
  storageBucket: "action-rse-c3198.appspot.com",
  messagingSenderId: "74042106324",
  appId: "1:74042106324:web:7754eb4ca396bb00fc7641"
};


const app = firebase.initializeApp(firebaseConfig);
const storage = getStorage(app);
const db = getFirestore(app);
const database = {
  formatDoc: snapshot => {
    return { id: snapshot.id, ...snapshot.data() }
  }
}
const auth = getAuth(app)
export {auth, db, storage, database} 
