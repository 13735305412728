
export const GetPracticesData = () => { 
    return new Promise((resolve, reject) => {      
          const practices = [
            {
                id: '1',
                text: "La gouvernance de l’organisation.",
                subText: "Système par lequel une organisation prend des décisions et les applique en vue d’atteindre ses objectifs",
            },
            {
                id: '2',
                text: "Les droits de l’homme.",
                subText: "Respect de l’individu, de sa dignité et de ses droits fondamentaux individuels, collectifs et sociaux. Lutte contre la discrimination et promotion de l’égalité des chances. Protection des données personnelles et /ou confidentielles des parties prenantes",
            },
            {
                id: '3',
                text: "Les relations et conditions de travail.",
                subText: "Pérennité et progression des compétences des collaborateurs. Intégration des collaborateurs par la communication. Protection de leur santé et de leur sécurité. Juste rémunération des collaborateurs, « équitable et transparente »",
            },
            {
                id: '4',
                text: "L’environnement.",
                subText: "Respect des lois environnementales. Gestion des déchets. Gestion de la pollution atmosphérique. Gestion et pollution de l’eau. Norme ISO 14001 ou 50001. Mise en place d’un Plan Mobilité. Réduction et recyclage des déchets. Gestion responsable des ressources, eau et énergie. Calcul de l’empreinte environnementale.",
            },
            {
                id: '5',
                text: "La loyauté des pratiques.",
                subText: "Lutte contre la corruption. Engagement politique responsable. Concurrence loyale. Promotion de la responsabilité sociétale dans la chaîne de valeur. Respect des droits de propriété",
            },
            {
                id: '6',
                text: "Les questions relatives aux consommateurs.",
                subText: "Communication d’informations fiables. Protection de la santé et de la sécurité des consommateurs. Consommation durable. Résolution des conflits. Protection des données et vie privée. Accès aux produits et services pour les populations les plus fragiles.",
            },
            {
                id: '7',
                text: "Les communautés et le développement local. ",
                subText: "Contribution au bien-être des communautés. Renforcement des partenariats locaux. Création de valeurs partagées",
            },
          ]
          setTimeout(() => resolve(practices), 1000)
        }) 
    
     }