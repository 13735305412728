import React, { useContext, useRef, useState, useEffect } from 'react';
import { UserContext } from '../context/userContext';
import { useNavigate } from 'react-router-dom';
import { MdClose } from 'react-icons/md';
import { VscEyeClosed  } from 'react-icons/vsc'
import { VscEye  } from 'react-icons/vsc'
import { collection, setDoc, doc, addDoc, getDocs, where, query, serverTimestamp} from "firebase/firestore";
import { db } from "../firebase-config"
import { TbEyeClosed } from "react-icons/tb";
import { TbEye } from "react-icons/tb";

export default function SignIn(props) {

    const {signIn, toggleModals, setStayLoggedIn} = useContext(UserContext);



    const navigate = useNavigate();

    const [isMailStored, setIsMailStored] = useState(false);
    const [storedMail, setStoredMail] = useState("");
    const [validation, setValidation] = useState("");

    const inputs = useRef([])
    const addInputs = el => {
        if (el && !inputs.current.includes(el)) {
            inputs.current.push(el)
        }
    }

    useEffect(() => {
        if(window.localStorage.mail) {
            setStoredMail(window.localStorage.mail) 
            setIsMailStored(true)
        } else {
            setStoredMail("")  
            setIsMailStored(false)
        }
            console.log(storedMail);
    }, [])




    const signInRef = useRef();

    const handleForm = async (e) => {
        e.preventDefault()


        if(inputs.current[2].checked) {
            localStorage.setItem('mail', inputs.current[0].value)
            setIsMailStored(true)
        } else {
            localStorage.removeItem('mail', inputs.current[0].value)
            setIsMailStored(false)
        }
            try {
               await signIn(
                        inputs.current[0].value,
                        inputs.current[1].value
                        );
                        console.log(e);
                        setValidation("")
                        navigate('/account')
                 
                } catch {
                    setValidation("Oops ! L'adresse mail et/ou le mot de passe est incorrect")
                }
                 
                if(inputs.current[3].value === "Oui") {
                    setStayLoggedIn(true)
                } else {
                    setStayLoggedIn(false)
                }
            
         }

    const handleType = () => {
        if(props.type === "password") {
            props.setType('text')
        } else {
            props.setType("password")
        }
    }

    return (
        <>
            <form ref={signInRef} onSubmit={handleForm} className="signUpForm">
                <p className="validationText">{validation}</p>

                <input ref={addInputs} type="mail" required name="email" className='form-control' id='mail' value={storedMail} onChange={(e) => setStoredMail(e.target.value)} placeholder='Adresse mail'/>
   
                <div className="row"><input ref={addInputs} type={props.type} required name="pwd" className='form-control' id='pwd' placeholder='Mot de Passe' />  <div className="showPassword" onClick={handleType}>{props.type === "password" ? <TbEye /> : <TbEyeClosed />}</div></div>
               
                <button className='submit'>S'identifier</button>
                <div className="storeMail">
                    <input ref={addInputs} value="Oui" checked={isMailStored ? true : false} onChange={() => setIsMailStored(!isMailStored)} type="checkbox"></input> <label htmlFor="">Mémoriser l'adresse mail</label>
                </div>
                <div className="stayLoggedIn">
                    <input ref={addInputs} value="Oui" type="checkbox"></input> <label htmlFor="">Rester connecté ?</label>
                </div>
                {
                    props.signUp !== 'forgotPwd' &&
                    <span className="resetPwdButton" onClick={() => props.setSignUp('forgotPwd')}>Mot de passe oublié ?</span>
                }
            </form>

        </>

    )
}