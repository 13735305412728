import React from 'react'
import './App.css';
import HomePage from './pages/HomePage';
import Archives from './pages/Archives';
import Nav from './components/Nav';
import Footer from './components/Footer';
import {HashRouter as BrowserRouter, Route, Routes } from "react-router-dom";
import MentionsLegales from './pages/MentionsLegales';
import LogInPage from './pages/LogInPage';
import AddEvents from './pages/AddEvents';
import Account from './pages/Account';
import ArchivesList from './pages/ArchivesList';
import SelectedArchive from './pages/SelectedArchive';

function App() {

  return (

    <BrowserRouter basename="/">
       <Nav />
       <Routes>
          <Route exact path="/" element={<HomePage />}></Route>
          <Route path="/home" element={<HomePage />}></Route>
          <Route path="/archives" element={<Archives />}></Route>
          <Route path="/archives/:id" element={<SelectedArchive />}></Route>
          <Route path="/login" element={<LogInPage />}></Route>
          <Route path="/mentions_legales" element={<MentionsLegales />}></Route>
          <Route path="/ajouter_evenements" element={<AddEvents />}></Route>
          <Route path="/liste_archives" element={<ArchivesList />}></Route>
          <Route path="/account" element={<Account />}></Route>
       </Routes>
       <Footer />
      </BrowserRouter>
 
  );
}

export default App;
