export const GetPillarsData = () => { 
    return new Promise((resolve, reject) => {      
          const pillars = [
            {
                id: '1',
                title: "Économique",
                list: 
                [
                    "Satisfaire et fidéliser les clients",
                    "Assurer la qualité de service",
                    "Soutenir les fournisseurs locaux",
                    "Régler les factures dans les délais",
                    "Faire preuve de transparence auprès des investisseurs"
                ]
            },
            {
                id: '2',
                title: "Social",
                list: 
                [
                    "Renforcer l’égalité des chances et la diversité",
                    "Garantir l’hygiène et la sécurité sur le lieu de travail",
                    "Faire respecter pleinement le droit du travail",
                    "Former le personnel"
                ]
            },
            {
                id: '3',
                title: "Environnemental",
                list: 
                [
                    "Réduire les émissions de gaz à effet de serre (GES)",
                    "Utiliser les ressources naturelles de manière durable",
                    "Limiter les déchets, notamment ceux qui sont dangereux",
                    "Favoriser la diversité biologique"
                ]
            }
          ]
          setTimeout(() => resolve(pillars), 1000)
        }) 
    
     }