import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import {gsap} from "gsap"

export default function ArchivedElement({archive, handleFullImg}) {

  let id = archive.ID;

  const [formattedDate, setFormattedDate] = useState("")
  
  const navigate = useNavigate()

  const selectArchive = async () => {
    navigate(`/archives/${id}`, { state: { archive, formattedDate } })

  }

  useEffect(() => {
    if(archive.start) {

      const [day, month, year] = archive.start.split('/');


      const formattedDate = dayjs(`${month}/${day}/${year}`).locale('fr').format('D MMMM YYYY');

      setFormattedDate(formattedDate)
    }
  }, [archive])



  console.log(formattedDate);

  return (
    <div className="archivedElement" id={id} onClick={() => selectArchive()}>

              <div className="illustration">
                  {(archive.fichiers.length > 0 && archive.fichiers[0].type === "image/jpeg") ? 
                   <img src={archive.fichiers[0].url} alt="" />
                    :
                   <div className="no_illustration">
                    <div className="logo">
                     <img src="https://action-rse.fr/Assets/action_rse_logo.png" alt="" />
                    </div>
                   </div>
                  }
              </div>
              <div className="info">
                <div className="entitle">
                  <h5>{archive.title}</h5>
                  <strong className="date" style={{textAlign: "center"}}>{formattedDate}</strong>
                  {(archive.categories.length > 0 && archive.categories[0].category !== 'default') && 
                  <div className="categories">
                    {archive.categories.filter(item => item.category !== "default").map(category => {
                    return <span key={category.id} className="category">{category.category}</span>
                    })}
                  </div>
                  }
                </div>
                {(archive.descriptif !== "" && archive.descriptif !== undefined) &&
                    <div className="descriptif">
                      <p>{archive.descriptif.length > 80 && `${archive.descriptif.slice(0, 60)} [...]`}</p>
                    </div>
                }

              </div>
    </div>
  )
}
