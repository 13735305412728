import React, {useEffect, useState} from 'react'
import { IoMdCamera } from "react-icons/io";
import { FaCircleMinus } from "react-icons/fa6";
import {v4 as uuidv4} from 'uuid'


export default function AddPictures({label, handlePictures, id, imgs, removeImg}) {

    const [thumbnail, setThumbnail] = useState([])

    useEffect(() => {
      if(imgs) {

        let filter = imgs.filter(img => img.id === id)
        setThumbnail(filter)
      }
    }, [imgs])

    console.log(thumbnail);
    

  return (
    <div className="row" id="picture_row">
    <label htmlFor="">{label}</label>
    <div className="img_row">
    <div className="add_img_box">
      <IoMdCamera />
      <input type="file" name="" id={id} onChange={(e) => handlePictures(e)} accept="image/png, image/jpeg, image/svg, image/bmp" />
    </div>
    {thumbnail.length > 0 &&
      <div className="thumbnails">

        {thumbnail.map(img => (
        <div key={uuidv4()} className="img_box">
          <div className="remove">
            <FaCircleMinus onClick={() => removeImg(img.name)} />
          </div>
          <div className="bkgd"  style={{backgroundImage: `url(${img.src})`}}></div>
          <img className="actualImg" src={img.src} alt="" />
        </div> 
        ))}      
      </div>
      }
   </div>
</div>
  )
}
