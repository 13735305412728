import React, { useState, useEffect, useContext } from 'react'
import { HashLink as Link } from 'react-router-hash-link';
import DropDown from './DropDown';
import { UserContext } from '../context/userContext';
import { DeviceWidthContext } from '../context/deviceWidthContext';
import { FaUser } from "react-icons/fa";
import { gsap } from 'gsap';

export default function Nav() {

    let mq = gsap.matchMedia()

    const { toggleMobileNav, mobileNavActive, deviceWidth } = useContext(DeviceWidthContext) 

    const [scrollYPosition, setScrollYPosition] = useState(0);
    const [currentUserMail, setCurrentUserMail] = useState("")
    const { currentUser, userData } = useContext(UserContext)
    const [currentScrollPosition, setCurrentScrollPosition] = useState(50);

    const [nav, setNav] = useState([
      {
        id: '1',
        titre: "Action RSE",
        url: "#action_Rse",
        subMenu: [
          {
            titre: "L'équipe",
            url: "#team",
          },
        ]
      },
      {
        id: '2',
        titre: "Qu’est-ce que la RSE ?",
        url: "#qu_est_ce_que_la_rse",
        subMenu: [
          {
            titre: "Les 3 piliers de la RSE",
            url: "#les_3_piliers_de_la_RSE",
          },
          {
            titre: "Pour quelle plus-value ?",
            url: "#la_RSE_pour_quelle_plus_value",
          },
          {
            titre: "Mon quizz RSE",
            url: "#tester_mes_connaissances",
          },
        ]
      },
     
      {
        id: '3',
        titre: "Améliorer mes pratiques",
        url: "#ameliorer_mes_pratiques_RSE",
        subMenu: [
          {
            titre: "L’actu du mois",
            url: "#actuMensuelle",
          },
          {
            titre: "Des actions locales",
            url: "#des_idees_pour_demarrer",
          },
          {
            titre: "Mon bilan carbone",
            url: "#mon_bilan_carbone",
          },
        ]
      },
      {
        id: '4',
        titre: "Les experts BFC",
        url: "#les_experts_regionaux_de_la_RSE",
        subMenu: [
          {
          }, 
        ]
      },
      {
        id: '5',
        titre: "Normes et Labels RSE",
        url: "#normes_et_labels",
        subMenu: [
          {
            titre: "Normes RSE",
            url: "#les_normes_RSE",
          },
          {
            titre: "Des labels",
            url: "#les_labels",
          },
        ]
      },
      {
        id: '6',
        titre: "Actualité",
        url: "#ateliers_RSE",
        subMenu: [
          {
            titre: "Les ateliers",
            url: "#les_ateliers_RSE",
          },
          {
            titre: "Archives",
            url: "archives",
          },
        ]
      },
      {
        id: '7',
        titre: "Nous contacter",
        url: "#contact",
        subMenu: [{}]
      }
    ])

    const showNavItem = async (id) => {
      if(!id) {
       console.error("élément ciblé non valide") 
      } else {
        mq.add("screen and (max-width: 3090px)", () => { 
          let ctx = gsap.context(() => {
            gsap.to(`#${id} .subMenu`, {
              display: "flex",
              opacity: "100%",
              duration: 0.1,
            })
          })         
        })
      }
    }

    const hideNavItem = async (id) => {
      if(!id) {
       console.error("élément ciblé non valide") 
      } else {
        mq.add("screen and (max-width: 3090px)", () => { 
          let ctx = gsap.context(() => {
            gsap.to(`#${id} .subMenu`, {
              display: "none",
              opacity: "0%",
              duration: 0.1,
            })
          })
          
        })
      }
    }

    const setNavFullHeigth = async () => {

      mq.add("screen and (max-width: 3090px)", () => { 
        let ctx = gsap.context(() => {
          gsap.to(`.nav`, {
            height: "180px",
            clipPath: "polygon(13% -10%, 75% -171%, 91% 50%, 83% 170%, 18% 173%, 8% 49%)",
            duration: 0.1,
            })
          })
          
        })
    }

    const setNavMinHeigth = async () => {

      mq.add("screen and (max-width: 2090px)", () => { 
        let ctx = gsap.context(() => {
          gsap.to(`.nav`, {
            height: "40px",
            clipPath: "polygon(13% -10%, 77% -261%, 89% 49%, 86% 143%, 15% 164%, 11% 49%)",
            duration: 0.1,
            })
          })
          
        })
    }

    const handleMobileNav = async () => {
    
      if(mobileNavActive) {
        gsap.to('.navigation', {
          height: '100vh'
        })
        gsap.to('.navigation #nav', {
          left: 0
        })
      } else {
        gsap.to('.navigation #nav', {
          left: '100%',
          duration: 0.2
        })
        gsap.to('.navigation', {
          height: 'auto',
          duration: 0.3
        })
      }
    }

    const getScrollPosition = async () => {
      if(scrollYPosition > currentScrollPosition) {
        mq.add("screen and (max-width: 2090px)", () => { 
          let ctx = gsap.context(() => {
            gsap.to(`.navigation`, {
              transform: "translateY(-25px)",
              opacity: 0,
              duration: 0.3
            })
          })
        })
      } else {
        mq.add("screen and (max-width: 2090px)", () => { 
          let ctx = gsap.context(() => {
            gsap.to(`.navigation`, {
              opacity: 1,
              transform: "translateY(10px)",
              duration: 0.3
            })
           
          })
          
        })
      }
      setCurrentScrollPosition(scrollYPosition)
    }


    const handleScroll = () => {
      const newScrollYPosition = window.scrollY;
      setScrollYPosition(newScrollYPosition);

     }

    useEffect(() =>{
      if(!(currentUser === null || currentUser === undefined)) {
        setCurrentUserMail(currentUser.auth.currentUser.email)
      }
    }, [])

    useEffect(() => {
      if((currentUser && nav.some(item => item.id !== "8"))) {
        setNav(prev => [...prev, 
        {
          id: '8',
          titre: <FaUser/>,
          url: "account",
          subMenu: [{}]

        }
      ])
      }
    }, [currentUser])
    
  


  useEffect(() => {
    mq.add("screen and (max-width: 3090px)", () => { 
      let ctx = gsap.context(() => {
        gsap.to(`.navigation`, {
          filter: "blur(0px)",
          opacity: 1,
          duration: 2
        })

      })

    })
  }, [])

    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, []);


  useEffect(() => {
    getScrollPosition()
  }, [scrollYPosition])


  useEffect(() => {
    handleMobileNav()
  }, [mobileNavActive])




  console.log(mobileNavActive);


  return (
    <div className='navigation' onMouseEnter={() => setNavFullHeigth()} onMouseLeave={() => setNavMinHeigth()}>
      {deviceWidth > 1200 ?

      <ul className="nav"> 
        <img className="picto" src="./Assets/bkgd_nav.png" alt="" />
      {nav.map(item => {
        return (
          <li key={item.id} id={`nav-item-${item.id}`} onMouseEnter={() => showNavItem(`nav-item-${item.id}`)} onMouseLeave={() => hideNavItem(`nav-item-${item.id}`)}>
            <Link to={`/${item.url}`}>{item.titre}</Link>
            {(item.subMenu.length > 0 && Object.keys(item.subMenu[0]).length > 0) &&
            <DropDown index={item.id} subMenu={item.subMenu} />
           }
          </li>
          )
        })}  
      </ul>

      :

      <>
       <ul onClick={() => toggleMobileNav(!mobileNavActive)} id="burgerNav" className={mobileNavActive ? "activeBurgerNav" : "inactiveBurgerNav"}>
         <li className="bar"></li>
         <li className="bar"></li>
         <li className="bar"></li>
       </ul>
      <ul id="nav" className={mobileNavActive ? "activeNav" : "inactiveNav"}>
      {nav.map(item => {
        return (
          <li key={item.id} id={item.id}>
            <Link to={`/${item.url}`} onClick={() => toggleMobileNav(false)}>{item.titre}</Link>
          </li>
          )
        })}  
      </ul>
      </>
      }
    </div>
  )
}
