import React, {useState} from 'react';
import AddEventForm from './AddEventForm';
import { CiEdit } from "react-icons/ci";

export default function DetailedArchive({archive, setEditMode, setEditModeContent}) {

  const handleEditMode = async (archive) => {
    
    const [startDay, startMonth, startYear] = archive.start.split('/');
    const [endDay, endMonth, endYear] = archive.end.split('/');
    const startDate = new Date(`${startMonth}-${startDay}-${startYear}`)
    const endDate = new Date(`${endMonth}-${endDay}-${endYear}`)

    setEditModeContent({
      id: archive.ID,
      title: archive.title,
      categories: archive.categories,
      start: startDate,
      end: endDate,
      lieu: archive.lieu,
      animateurs: archive.animateurs,
      descriptif: archive.descriptif,
      fichiers: archive.fichiers,
    })
    setEditMode(true)
  }

  
  return (
    <div className='archive'>
       <h4>{archive.title}</h4>
       <div className="info">
        {(archive.categories.length > 0 && archive.categories[0].category !== "default") &&
        <div className="categories">
          {archive.categories.filter(option => option.category !== 'default').map(category => {
           return <span key={category.id} className="category">{category.category}</span>
          })}
        </div>
        }
        <p style={{fontWeight: "600"}}>Date : {archive.start} - {archive.end}</p>
        <p style={{color:"#888888"}}>{archive.descriptif}</p>
        <p style={{fontWeight: "600"}}>Animateur</p>
        <ul className="animateurs">
        {archive.animateurs.map(animateur => (
            <li>{animateur.nom}</li>
        ))}
        </ul>
        <p style={{fontWeight: "600"}}>Lieu : {archive.lieu.nom}</p>
        {archive.fichiers.map(fichier => (
            <p>{fichier.name}</p>
        ))}

        <button onClick={() => handleEditMode(archive)}><CiEdit/> Modifier</button>
       </div>
    </div>
  )
}
