import React, {useEffect, useState, useContext} from 'react'
import { DeviceWidthContext } from '../context/deviceWidthContext';
import { useLocation, useParams } from 'react-router-dom';
import BackButton from '../components/BackButton';

export default function SelectedArchive() {

  const { deviceWidth } = useContext(DeviceWidthContext) 


  const [timeStamp, setTimeStamp] = useState("")

  const { id } = useParams(); 
  const location = useLocation(); 
  const archive = location.state?.archive;
  const formattedDate = location.state?.formattedDate;


  const replacements = {
    '/': ' / ',
    '2024': '2024  |',
  };

  console.log(archive);

  const replaceAll = (text, replacements) => {
    let result = text;

    Object.entries(replacements).forEach(([key, value]) => {
      result = result.replaceAll(key, value);
    });

    return result;
  };

  const updatedText = replaceAll(timeStamp, replacements);

  const formatTime = async () => {
  
      if(archive.start.toLocaleString().slice(0,10) === archive.end.toLocaleString().slice(0,10)) {
        setTimeStamp(archive.start.toLocaleString().replace(":", "h").slice(10, 16) + " - " + archive.end.toLocaleString().replace(":", "h").slice(11, 16))
      } else {
        setTimeStamp(archive.start.toLocaleString().replace(":", "h").slice(10, 16) + " - " + archive.end.toLocaleString().replace(":", "h").slice(0, 16))
      }
    }
  
  
    useEffect(() => {
      formatTime()
      window.scrollTo(0,0)
    }, [])

  
  return (
    <div className='selectedArchive'>

        <div className="content">
            <BackButton path={'archives'}/>
            <div className="archive" style={{display: `${(archive.fichiers.length > 0 && deviceWidth > 1000) ? 'grid' : 'flex'}` }}>
           
                {archive.fichiers.length > 0 &&
                <div className="files" style={{gridTemplateColumns: `repeat(${(archive.fichiers.length - 1)}, 1fr)`}}>
                {archive.fichiers.map((fichier, index) => {
                    return (<div className="file" style={{backgroundImage: `url(${fichier.url})`, gridColumn: index === 0 ? "1 / -1" : "auto"}}><a target="_blank" href={fichier.url}></a></div>)
                })}
                </div>  
                }

                <div className="info">
                    <div className="entitle">
                        <h1>{archive.title}</h1>
                        <strong className="date" style={{textAlign: "center"}}>{formattedDate} | {updatedText}</strong>
                        {archive.categories && 
                        <div className="categories">
                        {archive.categories.filter(item => item.category !== "default").map(category => (
                            <span key={category.id} className="category">{category.category}</span>
                        ))}
                        </div>
                        }
                                    
                    </div>
                    
                    {(archive.descriptif !== "" && archive.descriptif !== undefined) &&
                        <div className="descriptif">
                        <p>{archive.descriptif}</p>
                        </div>
                    }
                    <div className="cardContent">
                    {(archive.animateurs.length > 0 && Object.values(archive.animateurs[0]).some(value => value !== "")) && 
                        <div className="animators" style={{gridTemplateColumns: `${archive.animateurs.length + 1}fr`}}>
                        <h5>Animé par :</h5>
                        {archive.animateurs && archive.animateurs.map((animator, index) =>  (
                                                    
                            animator.nom !== '' &&
                            <div className="animator" key={index}>
                            <a target="_blank" rel="noopener noreferrer" href={animator.url}>
                            <img src={animator.logo.src} alt="" title={animator.nom}/>
                            </a>
                            <a target="_blank" href={animator.url}>{animator.nom}</a>
                        </div>
                        )
                        )}
                        </div> 
                        }
                        {Object.values(archive.lieu).every(value => value !== "") &&
                        <div className="lieu">
                            <h5>Lieu :</h5>
                            <div className="box">
                            {archive.lieu.logo ? 
                                <div className="lieu_logo">
                                <a target="_blank" href={archive.lieu.url}>
                                    <img src={archive.lieu.logo.src} alt="" title={archive.lieu.nom}/>
                                </a>
                                </div> 
                                        
                                :
                                ""
                            }
                            <a target="_blank" href={archive.lieu.url}>{archive.lieu.nom}</a>
                            </div>
                        </div>
                    }
                            
                    </div>
                </div>
            </div>    
    
        </div>
    </div>
  )
}
