export const GetTeamData = () => { 
    return new Promise((resolve, reject) => {      
          const team = [

            {
                id: '1',
                name: "Stéphane JOLY",
                professionalFunction: "Responsable équipe Entreprise - IDEIS",
                tel: "06 52 98 53 50",
                pic: "https://action-rse.fr/Assets/stephane_joly.jpg"
              },
              {
                id: '2',
                name: "Céline FERRARIO",
                professionalFunction: "Chargée de relations entreprises - IDEIS",
                tel: "07 68 43 11 14",
                pic: "https://action-rse.fr/Assets/celine_ferrario.jpg"
              },
              {
                id: '3',
                name: "Maud PETIT",
                professionalFunction: "Chargée de projet RSE et Mécénat - IDEIS",
                tel: "06 28 46 43 01",
                pic: "https://action-rse.fr/Assets/maud_petit.png"
              },
              {
                id: '4',
                name: "Claire PRIVAT",
                professionalFunction: "Chargée de projet RSE - MIFE-IDEIS",
                tel: "06 34 09 34 18",
                pic: "https://action-rse.fr/Assets/claire_privat.png"
              }
          ]
          setTimeout(() => resolve(team), 1000)
        }) 
    
     }