import React from 'react'

export default function Label({name, url, logo}) {
  return (
    <div className="label">
        <a target="blank"href={url} className='box'>
        <p>{name}</p>
        <img src={logo} alt="" />
        </a>
    </div>
  )
}
