import React from 'react'
import { useNavigate } from 'react-router-dom'
import { TiArrowBack } from 'react-icons/ti'

export default function BackButton({path}) {

  const navigate = useNavigate()

  return (
    <button className="back" onClick={() => navigate(`/${path}`)}><TiArrowBack />Retour</button>
  )
}
