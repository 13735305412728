import { createContext, useState, useEffect } from "react";

export const DeviceWidthContext = createContext()

export function DeviceWidthContextProvider(props) {

    const [deviceWidth, setDeviceWidth] = useState(null);
    const [mobileNavActive, setMobileNavActive] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);


    const toggleModal = async () => {
        setModalOpen(!modalOpen)
    }

    const toggleMobileNav = async (param) => {
        setMobileNavActive(param)
    }
  
    const handleResize = () => {
      setDeviceWidth(window.innerWidth);    
      setLoading(false)
  };
  
  
  useEffect(() => {
     handleResize()
  
      window.addEventListener('resize', handleResize);
  
      return () => {
          window.removeEventListener('resize', handleResize);
      };
    
  }, [])

  useEffect(() => {
    if(setModalOpen !== undefined) {
        setLoading(false)
    } else {
        console.log('undefined');
        
    }
  }, [])

  console.log(modalOpen)
  console.log(setModalOpen)
  

    return (
        <DeviceWidthContext.Provider value={{deviceWidth, toggleMobileNav, mobileNavActive, modalOpen, toggleModal}}>
            {!loading && props.children}
        </DeviceWidthContext.Provider>
    )

}