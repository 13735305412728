import React, {useState, useEffect, useRef} from 'react'
import { doc, setDoc, setDocs, addDoc, getDocs, collection, collectionGroup, updateDoc, deleteDoc, query, where, getDoc } from "firebase/firestore";
import {db, storage} from '../../firebase-config'
import { IoClose } from "react-icons/io5";
import { FaPlus } from "react-icons/fa";
import {v4 as uuidv4} from "uuid"
import { FaRegTrashAlt } from "react-icons/fa";
import { MdModeEditOutline } from "react-icons/md";

export default function AddCategory({setSelectedOptions, selectedOptions}) {

  const [options, setOptions] = useState([])
  const [addOptions, setAddOptions] = useState(false)
  const [openOptions, setOpenOptions] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [deleteSuccess, setDeleteSuccess] = useState(false)
  const [editSuccess, setEditSuccess] = useState(false)
  const [newCategoryValue, setNewCategoryValue] = useState("")
  const [optionToEdit, setOptionToEdit] = useState()
  const [editedOptionValue, setEditedOptionValue] = useState("")
  const [editMode, setEditMode] = useState(false)
  const [archiveEvents, setArchiveEvents] = useState([])
  const [agendaEvents, setAgendaEvents] = useState([])

  const inputRef = useRef()

  const loadCategory = async () => {
    setOptions([])
    const ref = collection(db, 'event_category')
    const querySnaphshot = await getDocs(ref)
    querySnaphshot.forEach((doc) => {
      setOptions(prev => [...prev, doc.data()])
    })
  }

  const selectNewOptions = async (option) => {
    console.log(selectedOptions.includes(option));
    
    if(!selectedOptions.includes(option)) {
      setSelectedOptions(prev => [...prev, option])
    } else {
      return
    }
  }

  const deleteOptions = async (e, id) => {
    e.stopPropagation()
    const ref = doc(db, 'event_category', id)
    const subColref  = query(collectionGroup(db, 'categories'), where("id", "==", id))  

    const querySnapshotCategory = await getDocs(subColref)

    querySnapshotCategory.forEach((document) => {

      const docRef = document.ref
      const parentRef = docRef.parent
      const documentReference = parentRef.parent
      const path = documentReference.path

      const ref = doc(db, `${path}`, "categories", id)

      console.log(ref);
    
      const deleteSubCollection = deleteDoc(ref);
    })

    await deleteDoc(ref)
    setDeleteSuccess(true)
  }

  const activateEditMode = async (e, option) => {
    console.log(option)
    e.stopPropagation()
    if(option) {
      setOptionToEdit(option)
      setEditedOptionValue(option.category)
      setEditMode(true)
    } else {
      return
    }
  }

  const editOptions = async (option) => {
    let editedOption = {
      id: option.id,
      category: editedOptionValue
    }
    const ref = doc(db, 'event_category', option.id)
    await updateDoc(ref, editedOption)
    setEditSuccess(true)
    setEditMode(false)
    
    const queryCategory = query(collectionGroup(db, 'categories'), where("id", "==", option.id))  

    const querySnapshotCategory = await getDocs(queryCategory)

    querySnapshotCategory.forEach((document) => {

      const docRef = document.ref
      const parentRef = docRef.parent
      const documentReference = parentRef.parent
      const path = documentReference.path

      const ref = doc(db, `${path}`, "categories", option.id)

      console.log(ref);
    
      const updateSubCollection = updateDoc(ref, editedOption);
    })
  }

  const removeCategory = async (e, category) => {
    e.stopPropagation()
    let newArray = selectedOptions.filter(option => option !== category)
    setSelectedOptions(newArray)
  }

  const addNewCategory = async (data) => {
    let id = uuidv4()

    const newCategory = {
      id: id,
      category: data
    }
    if(!data || data.trim() === "") {
      alert("Veuillez entrer une nouvelle catégorie")
    } else {
      try {
        const ref = doc(db, 'event_category', id)
        await setDoc(ref, newCategory)
      }
      catch (err) {
        console.log("Erreur : " + err)
      }

      setNewCategoryValue("")
      setSubmitSuccess(true)
    }
  }

  useEffect(() => {
    loadCategory()
  }, [submitSuccess, deleteSuccess, editSuccess])

  // useEffect(() => {
  //   loadEvents()
  // }, [])

  console.log(editedOptionValue);
  

  return (
    <div className="add_category">
      <div className="categoryField" onClick={(e) => setOpenOptions(!openOptions)}>
        {selectedOptions.length > 0 ? 
          selectedOptions.filter(option => option.category !== "default").map(selected => (
          <div key={selected.id} className="selected"><span>{selected.category}</span> <div className="close" onClick={(e) => removeCategory(e, selected)}><IoClose /></div></div>
        ))
         :
         <p className="placeholder">Ajouter une ou plusieurs catégories</p>
        }
      </div>
      {openOptions &&
        <div className="options">
          {editMode ?
            <>
            {options.filter(option => option.id !== optionToEdit.id).map(option => (
              <div className="option" key={option.id} onClick={() => selectNewOptions(option)}>
                {option.category}
                <div className="actions">
                  <div className="edit" onClick={(e) => activateEditMode(e, option)}><MdModeEditOutline /></div>
                  <div className="delete" onClick={(e) => deleteOptions(e, option.id)}><FaRegTrashAlt /></div>
                </div>
              </div>
            ))}
              <>
              <div className='editCategoryForm'>
                <input ref={inputRef} type="text" className="editOption" value={editedOptionValue} name="category" placeholder="Nouvelle catégorie" id="" onChange={(e) => setEditedOptionValue(e.target.value)} />
                <button type="button" onClick={() =>  editOptions(optionToEdit)}><MdModeEditOutline /></button>
              </div>

              <div className="edit_category" onClick={() => setEditMode(false)}>Annuler</div>
              </>
            </>

            :

            options.map(option => (
              <div className="option" key={option.id} onClick={() => selectNewOptions(option)}>
                {option.category}
                <div className="actions">
                  <div className="edit" onClick={(e) => activateEditMode(e, option)}><MdModeEditOutline /></div>
                  <div className="delete" onClick={(e) => deleteOptions(e, option.id)}><FaRegTrashAlt /></div>
                </div>
              </div>
            ))
          }

          {(!addOptions && !editMode) ? 
          <div className="add_new_category" onClick={() => setAddOptions(true)}>Ajouter une nouvelle catégorie <FaPlus /></div>
           
             : !editMode &&
    
              <>
              <div className='newCategoryForm'>
                <input ref={inputRef} type="text" value={newCategoryValue} name="category" placeholder="Nouvelle catégorie" id="" onChange={(e) => setNewCategoryValue(e.target.value)} />
                <button type="button" onClick={() => addNewCategory(newCategoryValue)}><FaPlus /></button>
              </div>

              <div className="add_new_category" onClick={() => setAddOptions(false)}>Annuler</div>
              </>

          }
        </div>
      }
       
    </div>
  )
}
