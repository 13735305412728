import React, { useContext, useEffect, useState } from 'react'
import { db } from '../firebase-config'
import { collection, doc, getDocs, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { TiArrowBack } from "react-icons/ti";
import ArchivedElement from '../components/Events/ArchivedElement';
import BackButton from '../components/BackButton';


export default function Archives() {

  const navigate = useNavigate()

  const [scrollYPosition, setScrollYPosition] = useState(0);
  const [archives, setArchives] = useState([]);

  const handleScroll = () => {
    const newScrollYPosition = window.pageYOffset;
    setScrollYPosition(newScrollYPosition);
   };

   useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, []);


  useEffect(() => {
    window.scrollTo(0, 0)
    loadArchives()
  }, [])

  const loadArchives = async () => {
    setArchives([])
    const ref = collection(db, "articles")
    const querySnapshot = await getDocs(ref)
    for (const doc of querySnapshot.docs) {
    
      const refSubCol = collection(db, 'calendrier', doc.id, 'categories')
                          
      const queryCategories = await getDocs(refSubCol)
                      
      let categories = []
              
      queryCategories.forEach(option => {
          categories.push(option.data())
      })

      let animateurs = doc.data().animateurs.filter(animateur => Object.values(animateur).every(value => value.nom !== ''))

      const [day, month, year] = doc.data().start.split('/');
      

      const date = new Date(`${month}-${day}-${year}`)

      setArchives(archive => [
        {
          ID: doc.data().ID,
          descriptif: doc.data().descriptif,
          categories: categories,
          animateurs: animateurs,
          lieu: doc.data().lieu,
          start: doc.data().start,
          end: doc.data().end,
          title: doc.data().title,
          fichiers: doc.data().fichiers,
          sortDate: date
        }
       , ...archive
      ])

    }

  }


  console.log(archives);
  

  return (
   <>

    <div className='archives'>
      <div className="content">
      <BackButton path="" />

        <h1>Événements passés du pôle action RSE Nord Franche-Comté</h1>
        <div className="elements">
          <h3>En 2024</h3>
          <div className="list" id="_2024">
            {archives && archives.filter(item => item.start.includes('2024')).sort((a,b) => b.sortDate -  a.sortDate).map(archive => (
              <ArchivedElement 
              archive={archive}
              />
            ))}
          </div>
         
          <h3>En 2023</h3>
          <div className="list" id="_2023">
            {archives && archives.filter(item => item.start.includes('2023')).sort((a,b) => b.sortDate -  a.sortDate).map(archive => (
              <ArchivedElement 
              archive={archive}
              />
            ))}
          </div>
     
        </div>
      </div>
     </div>
    </>
  )
}
