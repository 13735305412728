import React, { useRef, useState } from "react"
import { getAuth, sendPasswordResetEmail, signOut, deleteUser } from "firebase/auth"
import { Link } from "react-router-dom"
import {useForm} from 'react-hook-form';

export default function ResetPassword({ setForgotPassword }) {

    const { register, handleSubmit, setError, formState: {errors, isSubmitting, isSubmitted, isSubmitSuccessful }  } = useForm({
        mode: 'onTouched',
      })


    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)  
    const [email, setEmail] = useState('')

    const auth = getAuth()

    const resetPassword = async () => {
    console.log(email);
         await sendPasswordResetEmail(auth, email)
         .then(() => {
             setMessage('Email envoyé, pensez également à verifier votre dossier spam !')
             // ..
            }).catch((error) => {
        const errorCode = error.code;
        setError(error.message);
        setMessage('Une erreur s\'est produite, veuillez réessayer ultérieurement')
    });
    console.log(message);
    setLoading(false)
  }

  return (
    <form className="forgotPassword">
        <h3>Mot de passe oublié ?</h3>
        {message &&
            <div className={message === 'Email envoyé, pensez également à verifier votre dossier spam !' ? 'success' : 'error'}>{message}</div>
        }
           
                <input placeholder="Votre adresse mail" onChange={(e) => setEmail(e.target.value)} type="email" name="email" id="" />
                <button className="reset" onClick={resetPassword}>Envoyer</button>
           
      
        <h4 className="toSignIn" onClick={() => setForgotPassword('signIn')}>Se connecter</h4>
    </form>
  )
}
