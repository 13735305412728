import React, { useEffect, useState } from 'react'
import { db } from '../firebase-config'
import { collection, doc, getDocs, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import ArchivedElement from '../components/Events/ArchivedElement';
import DetailedArchive from '../components/Events/DetailedArchive';
import AddEventForm from '../components/Events/AddEventForm';

export default function ArchivesList({modalOpen, setModalOpen}) {

    const [archives, setArchives] = useState([]);
    const [editMode, setEditMode] = useState(false)
    const [editModeContent, setEditModeContent] = useState("")

    const loadArchives = async () => {
        setArchives([])
        const ref = collection(db, "calendrier")
        const querySnapshot = await getDocs(ref)
        for (const doc of querySnapshot.docs) {

          const refSubCol = collection(db, 'calendrier', doc.id, 'categories')
                      
          const queryCategories = await getDocs(refSubCol)
                  
          let categories = []
          
          queryCategories.forEach(option => {
              categories.push(option.data())
          })

          let animateurs = doc.data().animateurs.filter(animateur => Object.values(animateur).every(value => value.nom !== ''))

          const [day, month, year] = doc.data().start.split('/');
    
          const date = new Date(`${month}-${day}-${year}`)
    
          setArchives(archive => [
            {
              ID: doc.data().ID,
              descriptif: doc.data().descriptif,
              categories: categories,
              animateurs: animateurs,
              lieu: doc.data().lieu,
              start: doc.data().start,
              end: doc.data().end,
              title: doc.data().title,
              fichiers: doc.data().fichiers,
              sortDate: date
            }
           , ...archive
          ])
    
        }
    
      }

      
      useEffect(() => {
        loadArchives()
      }, [])
      
      console.log(archives);

  return (
    <div className='archiveList'>
        <h2>Les événements</h2>

        {editMode ?
        <>
         <button onClick={() => setEditMode(false)} style={{marginLeft: "40px", width: "10%"}}>Retour</button>
          <AddEventForm
           preloadedData={editModeContent}
           modal={modalOpen}
           setModal={setModalOpen}
           />
        </>
        :
        <div className="list">
        {archives.sort((a,b) => b.sortDate -  a.sortDate).map(archive => (
          <DetailedArchive
          archive={archive}
          setEditMode={setEditMode}
          setEditModeContent={setEditModeContent}
          key={archive.ID}
          />
         )
       )}
       </div>
      }
    </div>
  )
}
