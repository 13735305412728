import React from 'react'

export default function MentionsLegales() {
  return (
    <div className='mentions_legales'>
      <div className="content">

      <h1>Mentions légales</h1>

      <img src="https://action-rse.fr/Assets/action_rse_logo.png" alt="" />
      <p>
        <strong>Directeur de la publication :</strong> David Pan, <a href="mailto:david.pan@ideis-asso.fr">david.pan@ideis-asso.fr.</a><br/>
        <strong>Développement web :</strong> Nina Socrate,  <a href="mailto:nina.socrate@mife90.org">nina.socrate@mife90.org.</a><br/>

        <strong>Adresse : </strong>
        IDEIS - Centre des Alliés - 25200 Montbéliard <br/>
        <br/> <br/>
        <strong>Droit d’auteur – copyright : </strong> 
        L’ensemble de ce site relève de la législation française et internationale sur le droit d’auteur et la propriété intellectuelle. Tous les droits de reproduction sont réservés, y compris pour les documents téléchargeables et les représentations iconographiques et photographiques.
        La reproduction de tout ou partie de ce site sur un support électronique ou autre quel qu’il soit est formellement interdite sauf autorisation expresse.
        <br/> <br/>
        L’autorisation de reproduction des documents contenus dans ce site sera soumise au respect des conditions suivantes :
        – gratuité de la diffusion,
        – citation claire et lisible de la source.
        Exemple : « ce document provient du site internet Action-RSE.fr. Les droits de reproduction sont réservés et strictement limités ».
        <br/>
        Pour d’autres utilisations, veuillez nous consulter.
        <br/> <br/>
        Les demandes doivent être adressées à la développeuse.
        <br/> <br/>
        L’adresse de notre site est : <a href="https://action-rse.fr/">https://action-rse.fr/</a>
        <br/> <br/>
        Stockage et conservation de vos données
        Vous êtes informés de la collecte de données à caractère personnel par IDEIS.
        <br/> <br/>
        Le traitement de ces données a pour finalité l'exploitation des informations du quizz RSE.
        Ce traitement est fondé juridiquement sur l’art. 6 du Règlement Général sur la Protection des Données UE/2016/679, paragraphe 1, point e) : Mission de service public Les destinataires de ces données à caractère personnel sont les collaborateurs d'IDEIS Action-RSE NFC. Vous disposez d’un droit d’accès, de rectification, d’effacement ou d’opposition et de portabilité de vos données, que vous pouvez exercer à l’adresse susmentionnée ou par courriel à RGPD@bfc.cci.fr Vous disposez également d’un droit de réclamation auprès de la CNIL , 3 place Fontenoy – TSA 80715 – 75334 PARIS CEDEX 07.
        Ces données peuvent être supprimées sur demande.
        </p>
      
      </div>
    </div>
  )
}
