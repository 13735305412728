export const GetLocalActionsData = () => { 
    return new Promise((resolve, reject) => {      
          const actions = [
            {
                id: '1',
                name: "La charte de Responsabilité Sociale Territoriale",
                contact: "IDEIS Entreprises & Territoire",
                mail: "",
                tel: "06 52 98 53 50",
                logo: {
                  url: "https://www.ideis-asso.fr/projets-ideis",
                  img: "/Assets/IDEIS_logo-01-removebg-preview.png"
                }

            },
            {
                id: '2',
                name: "La Dotation d’Action Territoriale",
                contact: "Maud PETIT | IDEIS",
                mail: "maud.petit@ideis-asso.fr",
                tel: "06 28 46 43 01",
                logo: {
                  url: "https://www.dat-france.org/",
                  img: "https://action-rse.fr/Assets/logo_DAT_Break_Poverty.png"
                }

            },
            {
                id: '3',
                name: "Le label Empl’itude",
                contact: "Sylvaine Boilloux | MIFE",
                mail: "sylvaine.boilloux@mife90.org",
                tel: "07 56 42 51 18",
                logo: {
                  url: "https://mife90.org/emplitude/",
                  img: "https://action-rse.fr/Assets/logo-EMPLITUDE-TdB-transparent.png"
                }

            },
            {
                id: '4',
                name: "Le club les entreprises s’engagent 90",
                contact: "Julie WERMELINGER | CREPI Alsace Franche-Comté",
                mail: "julie.wermelinger@crepi.org",
                tel: "07 66 17 87 10",
                logo: {
                  url: "https://lesentreprises-sengagent.gouv.fr/clubs/90",
                  img: "https://action-rse.fr/Assets/d3JS35CaQ9e29yp9exD0.webp"
                }

            },
            {
                id: '5',
                name: "Le club les entreprises s’engagent 25",
                contact: "Frédérique GENTNER-MARMIER | club FACE Doubs",
                mail: "f.gentner@fondationface.org",
                tel: "06 76 92 88 30",
                logo: {
                  url: "https://lesentreprises-sengagent.gouv.fr/clubs/25",
                  img: "https://action-rse.fr/Assets/BXiWzbP5QReiAc3Ew8eg.webp"
                }

            }
          ]
          setTimeout(() => resolve(actions), 1000)
        }) 
    
     }
