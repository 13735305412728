import React, { useState, useEffect, useRef, lazy, Suspense, useContext } from 'react'
import { gsap } from 'gsap';
import { HashLink as Link } from 'react-router-hash-link';
import { MdArrowRight, MdArrowDropDown, MdOutlineCampaign, MdLightbulbOutline, MdOilBarrel, MdOutlineRecycling } from "react-icons/md";
import { FaArrowTrendUp } from "react-icons/fa6";
import { GiTeamIdea, GiScales, GiPartyPopper, GiEuropeanFlag } from "react-icons/gi";
import { AiOutlineDeploymentUnit, AiOutlineRise  } from "react-icons/ai";
import { TbTargetArrow } from "react-icons/tb";
import { PiLightbulbFilamentFill, PiLinkSimpleHorizontalBreakLight, PiSealCheckFill } from "react-icons/pi";
import { CgEuro } from "react-icons/cg";
import { IoCheckmarkDoneOutline } from "react-icons/io5";
import { DeviceWidthContext } from '../context/deviceWidthContext';
import { GetPillarsData } from "../data/GetPillarsData";
import { GetTeamData } from "../data/GetTeamData";
import { GetLabelsData } from "../data/GetLabelsData";
import { GetRSEExpertsData } from "../data/GetRSEExpertsData";
import { GetPracticesData } from "../data/GetPracticesData";
import { GetLocalActionsData } from "../data/GetLocalActionsData";
import Label from '../components/Label';

const Modal = lazy(() => import('../components/Modal'));
const RSECalendar = lazy(() => import('../components/Events/RSECalendar'));
const FadeInImg = lazy(() => import('../components/FadeInImg'));
const FadeInText = lazy(() => import('../components/FadeInText'));
const SlideInDiv = lazy(() => import('../components/SlideInDiv'));
const Caution = lazy(() => import('../components/Caution'));
const ScaleImg = lazy(() => import('../components/ScaleImg'));
const Person = lazy(() => import('../components/Person'));
const Carousel = lazy(() => import('../components/Carousel'));
const BouncingButton = lazy(() => import('../components/BouncingButton'));
const WhiteFrame = lazy(() => import('../components/WhiteFrame'));
const InteractivCard = lazy(() => import('../components/InteractivCard'));
const AnimatedText = lazy(() => import('../components/AnimatedText'));
const QuizzRseForm = lazy(() => import('../components/QuizzRseForm'));
const Pillar = lazy(() => import('../components/Pillar'));


export default function HomePage() {

  const { setModalOpen, modalOpen, toggleMobileNav, deviceWidth } = useContext(DeviceWidthContext) 

  const [team, setTeam] = useState([])
  const [pillarsContent, setPillarsContent] = useState([])
  const [labels, setLabels] = useState([{}])
  const [rseExperts, setRseExperts] = useState([])
  const [practices, setPractices] = useState([])
  const [localActions, setLocalActions] = useState([])
  

  console.log(team);
  
  const [hoveredPerson, setHoveredPerson] = useState(null);

  const whiteFrameContent = [
   {
    title: "La Responsabilité Sociétale des Entreprises désigne : ",
    text: "« l’intégration volontaire, par les entreprises, de préoccupations sociales et environnementales à leurs activités commerciales et leurs relations avec leurs parties prenantes».",
    author: {
      text: "Définition de la",
      img: "./Assets/commission_europ%C3%A9enne.svg",
      src: 'https://commission.europa.eu/business-economy-euro/doing-business-eu/sustainability-due-diligence-responsible-business/corporate-social-responsibility-csr_fr'
    }
   }, 
   {
    title: "Le Ministère de la Transition écologique, de l’Energie, du Climat et de la Prévention des risques précise que :",
    text: "La responsabilité d’une organisation vis-à-vis des impacts de ses décisions et de ses activités sur la société et sur l’environnement, se traduit par un comportement transparent et éthique qui : -Contribue au développement durable, y compris à la santé et au bien-être de la société. -Prend en compte les attentes des parties prenantes. -Est intégré dans l’ensemble de l’organisation et mis en œuvre dans ses relations.",
    author: {
      text: "",
      img: "./Assets/Logo_du_Gouvernement.png",
      src: 'https://www.ecologie.gouv.fr/politiques-publiques/responsabilite-societale-entreprises'
    }
   }, 
 ]
 
  const carbonFootprintElement = [
    {
      icon: CgEuro,
      text: "Réduire ses coûts liés à l’énergie",
    },
    {
      icon: MdOilBarrel,
      text: "Réduire sa dépendance aux énergies fossiles",
    },
    {
      icon: MdOutlineRecycling,
      text: "Réduire son impact environnemental",
    },
    {
      icon: AiOutlineRise,
      text: "Améliorer son image",
    },
    {
      icon: MdOutlineCampaign,
      text: "Sensibiliser ses équipes aux enjeux carbone",
    },
    {
      icon: TbTargetArrow,
      text: "Motiver ses équipes",
    },
    {
      icon: PiLightbulbFilamentFill,
      text: "Stimuler son innovation",
    },
    {
      icon: GiEuropeanFlag,
      text: "Anticiper de nouvelles réglementations",
    },
    {
      icon: AiOutlineDeploymentUnit,
      text: "Convaincre de nouveaux partenaires",
    },
 ]


  const [currentIndex, setCurrentIndex] = useState(0);

  const displayedWhiteFrame = whiteFrameContent[currentIndex];

  const blocRef = useRef(); 
  const secondBlocRef = useRef(); 

  const intervalRef = useRef(null)
  
  let mq = gsap.matchMedia()

  const [isQuizzDisplayed, setIsQuizzDisplayed] = useState(false)
  const [detailItem, setDetailItem] = useState([])
  const [modal, setModal] = useState()
  const [fullImgSrc, setFullImgSrc] = useState()
  const [scrollYPosition, setScrollYPosition] = useState(0);

  const [selectedEvent, setSelectedEvent] = useState({});

  const [calendarScrollPosition, setCalendarScrollPosition] = useState()
  const [hideNav, setHideNav] = useState(false)

  const calendarRef = useRef(null)

  
  

  
  const handleScroll = () => {
    const newScrollYPosition = window.scrollY;
    setScrollYPosition(newScrollYPosition);

    if(calendarRef.current) {
      const rect = calendarRef.current.getBoundingClientRect()
      const elementScrollPosition = rect.top + window.scrollY;
      setCalendarScrollPosition(elementScrollPosition)
    }
   }

   console.log(calendarScrollPosition);
   
   useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, []);

  const handleDetailedList= async (e) =>  {
    console.log(e.target.id);
    
    if(detailItem !== e.target.id) {
      setDetailItem(e.target.id)
    } else {
      console.log("doesn't work");
      
      setDetailItem()
    }
  }

  const startInterval = () => {
    console.log('start');
    
    if (!intervalRef.current) {
      intervalRef.current = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % whiteFrameContent.length);
     }, 5000);

      console.log("Interval started with ID:", intervalRef.current); // Debug
    }
   };

   const pauseInterval = () => {
     
    console.log("Trying to pause interval with ID:", intervalRef.current); // Debug
    if (!intervalRef.current) {
      console.warn("Pause called but no interval exists!"); // Avertissement
      return;
    }

    console.log("Interval paused");
    clearInterval(intervalRef.current);
    intervalRef.current = null;
    
  };
  
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {

          if (entry.isIntersecting) {
              let ctx = gsap.context(() => {
              
                gsap.to(`.bloc img`, {
                  filter: "grayscale(0) saturate(1.5)",
                  })
                })
          } else {
            let ctx = gsap.context(() => {
           
              gsap.to(`.bloc img`, {
                filter: "grayscale(1)",
                })
              })     
          }
        });
      },
      {
        threshold: 1,
      }
    );

    if (blocRef.current) {
      observer.observe(blocRef.current);
    }

    if (secondBlocRef.current) {
      observer.observe(secondBlocRef.current);
    }

    return () => {
      if (blocRef.current) {
        observer.unobserve(blocRef.current);
      }
      if (secondBlocRef.current) {
        observer.unobserve(secondBlocRef.current);
      }
    };
   
  }, []);

  const displayQuizz = async () => {
    setIsQuizzDisplayed(true)
    console.log(isQuizzDisplayed);
    
    let ctx = gsap.context(() => {
      gsap.to('.displayQuizz', {
        height: '0',
        opacity: 0,
        padding: 0,
        duration: 0.2
      })
      gsap.to('.formContainer', {
        height: '100%',
        duration: 0.2
      })
      gsap.to('.formContainer .form', {
        height: '100%',
        padding: deviceWidth > 800 ? "20px 40px" : "20px",
        duration: 0.2
      })
      gsap.to('.formContainer .form_part', {
        opacity: 1,
        height: '100%',
        delay: 0.3
      })
      gsap.to('.formContainer .score', {
        opacity: 1,
        delay: 0.3
      })
    })
  }

  useEffect(() => {
    const fetchData = async () => {
      setTeam(await GetTeamData())
      setPillarsContent(await GetPillarsData())
      setLabels(await GetLabelsData())
      setRseExperts(await GetRSEExpertsData())
      setPractices(await GetPracticesData())
      setLocalActions(await GetLocalActionsData())
    } 
    fetchData()

    // startInterval()
    // return () => clearInterval(intervalRef); 
    
  }, [])


  console.log(team);
  console.log(pillarsContent);
  console.log(labels);
  console.log(practices);


  return (
    <>
     {modal && 
     <Suspense fallback="">
       <Modal fullImgSrc={fullImgSrc} position={scrollYPosition}/>   
     </Suspense>
    }

    <div className="homePage" onClick={() => toggleMobileNav(false)}>
      <section className="whatIsIt">
      <div style={{marginTop: "-100px"}} id="action_Rse" className="anchor"></div>

        <div className="logos">
          <div className="imgBox">
            <a target="blank" href="https://www.ideis-asso.fr/">
              <img src="https://action-rse.fr//Assets/IDEIS_logo-01-removebg-preview.png" alt="" />
            </a>
          </div>
          <div className="imgBox">
          <img style={{width: "125px", margin: "20px auto 40px", padding: "20px",  display: "block", borderRadius: "25px"}} src="https://action-rse.fr/Assets/cofinance_par_ue.png" alt="" />
          </div>
          <div className="imgBox">
            <a target="blank" href="https://mife90.org/">
              <img src="https://action-rse.fr/Assets/logo-MIFE90-TRANSPARENT.png" alt="" />
            </a>
          </div>
          <div className="imgBox">
              <img src="https://action-rse.fr/Assets/logo_action_RSE.png" alt="" />
          </div>
        </div>

        </section>
        <div className="anchor" id="le_projet_Action_RSE_NFC"></div>
        <section id="paraph-3" className="paraph_3">

            <p>L'engagement social, environnemental, économique et territorial d'une entreprise est aujourd'hui un gage de reconnaissance et 
              donc d'attractivité. Action RSE NFC s’est donnée pour mission de promouvoir la RSE dans le Territoire de Belfort et dans le Pays 
              de Montbéliard Agglomération (PMA) et répond aux enjeux suivants :</p><br /><br /> 

            <ul style={{marginLeft: "150px", listStyleType: "none"}}>
                <li style={{fontWeight: "600", alignItems: "center", fontSize: "20px"}}><FaArrowTrendUp /> Faciliter l’accès aux informations sur la RSE aux petites et moyennes entreprises du Nord Franche-Comté</li>
                <li style={{fontWeight: "600", alignItems: "center", fontSize: "20px"}}><FaArrowTrendUp /> Participer au développement de l’attractivité des entreprises</li>
                <li style={{fontWeight: "600", alignItems: "center", fontSize: "20px"}}><FaArrowTrendUp /> Renforcer les démarches d’inclusion des entreprises</li>
                <li style={{fontWeight: "600", alignItems: "center", fontSize: "20px"}}><FaArrowTrendUp /> Développer la culture du mécénat social</li>
            </ul>
        </section>

        <div id="team" className="anchor" style={{marginTop: "160px"}}></div>

        <section className="team">
          <h2>Action RSE, une équipe au service des TPE / PME du Nord Franche-Comté </h2>
          {team.map(person => {
            return (
              <Suspense fallback="">
                <Person 
                id={person.index} 
                key={person.index}
                {...person}
                hoveredId={hoveredPerson}
                setHoveredId={setHoveredPerson}
                />
              </Suspense>
            )
          })}
        </section>

      
      <div className="anchor" id="qu_est_ce_que_la_rse" style={{marginTop: "-60px"}}></div>
      <section className="definition">
        <h2>Qu’est-ce que la RSE ?</h2>
       
           
    <div className="bloc" ref={blocRef}>
      <div className="imgBox">
          <img src="./Assets/photo_bloc.jpg" alt="" />
      </div>
      <div className="right">
        {deviceWidth > 800 ? 
          <Suspense fallback="">
            <WhiteFrame 
              item={displayedWhiteFrame} 
              pauseInterval={pauseInterval}
              startInterval={startInterval}
              />
          </Suspense>

          : 

          <div className="mobileDisplay">
            <strong className="title">{displayedWhiteFrame.title}</strong>

            <div className="quote">
             <p>{displayedWhiteFrame.text}</p> 
            </div>
            <div className="author">
              <a href={displayedWhiteFrame.author?.src} target="blank">
                <p>{displayedWhiteFrame.author?.text}</p>
                <img src={displayedWhiteFrame.author?.img} alt="" />
            </a>
            </div>
          </div>
        }
          
      </div>
    </div>
    </section>

        <Suspense fallback="">
          <Caution 
            text="Les entreprises engagées dans la RSE investissent dans le développement durable de leur activité" 
            strong="À noter ! La RSE est une démarche volontaire qui dépasse le simple respect de la législation." 
            icon={MdLightbulbOutline}
            link=""
            url=""
            color="#ffa66a"
            />
        </Suspense>   

        <div className="anchor" id="les_3_piliers_de_la_RSE" style={{marginTop: "-60px"}}></div>
        <section className='paraph_2'>
          <div className="asterisk">✳</div>
          <h2>Les 3 piliers de la RSE</h2>
          <p className='subTitle' style={{width: "80%", margin:"0 auto"}}>à prendre en compte pour préserver la planète et l’avenir des générations futures. Le développement d’une entreprise engagée dans une démarche de RSE doit se faire de manière équilibrée dans ces trois domaines pour être responsable et durable.</p>

          <div className="pillars">
            {pillarsContent.map((pillar, index)=> {
              return (
              <Suspense fallback="">
                <Pillar key={index} data={pillar} index={index} />
              </Suspense>
              )
            })}
          </div>
        
          <h4 style={{textAlign: "center", fontWeight: "600"}}>qui s’articulent autour de 7 questions centrales</h4>
          <a href="https://www.afnor.org/developpement-durable/demarche-iso-26000/" target="blank">
             <img className="afnor_img" style={{display: "block", margin: "20px auto"}} src="/Assets/7pointsNormes26000.png"/>
          </a>
          <p style={{float: "right", margin: "20px 0 50px", fontStyle: "italic"}}>Illustration extraite du site de l’AFNOR</p>

          <div className="anchor" id="la_RSE_pour_quelle_plus_value"></div>
        </section>
        <section className="grid">
        <h2 style={{margin: "60px auto", gridColumn: "1/3", gridRow: "1"}}>Pour quelle plus-value ?</h2>

            <ul className="plus_value_list">
              <li><IoCheckmarkDoneOutline /> Accroitre sa performance économique et commerciale</li>
              <li><IoCheckmarkDoneOutline /> Réduire ses coûts de fonctionnement</li>
              <li><IoCheckmarkDoneOutline /> Améliorer la qualité des ses produits ou services</li>
              <li><IoCheckmarkDoneOutline /> Limiter les pertes</li>
              <li><IoCheckmarkDoneOutline /> Améliorer la productivité et l’efficacité des équipes</li>
              <li><IoCheckmarkDoneOutline /> Valoriser l’image de marque de l’entreprise</li>
              <li><IoCheckmarkDoneOutline /> Attirer les talents, renforcer l’engagement et l’adhésion des collaborateurs</li>
            </ul>

            <a style={{gridRow: "2/3"}} target="blank" href="https://action-rse.fr/Assets/fs_etude_rse_finale (1).pdf">
             <Suspense fallback="">
               <ScaleImg src="https://action-rse.fr/Assets/Responsabilité sociale des entreprises et compétitivité_page-0001.jpg" />
             </Suspense>
            </a>
           
            <div style={{gridColumn: "1/3", margin: "50px"}}>
              <p style={{fontStyle: "italic"}}>Pour compléter : </p>
              <a target="blank" href='https://www.novethic.fr/entreprises-responsables/la-rse-dans-lentreprise.html'>
                <img style={{maxWidth: "265px"}} src="/Assets/novetic.png" alt="" />
              </a>
            </div>
        
        </section>
        <div className="anchor" id="ameliorer_mes_pratiques_RSE" style={{marginTop: "-60px"}}></div>
        <section>

        <section id="tester_mes_connaissances">
        <h2>Un petit challenge ?</h2>

            <button className="displayQuizz" onClick={() => displayQuizz()}>Le quizz de la RSE <GiPartyPopper /></button>
            <Suspense fallback>
              <QuizzRseForm />
            </Suspense>
          

        </section>
        <div className="grid_2">
          <h2 style={{margin: "100px auto"}}>Je veux améliorer mes pratiques</h2>

          <div className="needs">
            <h4>J'ai besoin de...</h4>

            <ul>
              <li>Être informé, sensibilisé aux principes de la RSE</li>
              <li>Être accompagné individuellement ou collectivement dans mon entreprise</li>
              <li>Financer un projet interne</li>
              <li>Valoriser mes actions RSE</li>
            </ul>
          </div>

          <div className="practices">
            <h4>J'agis sur...</h4>
            <dl>
              {practices.map((practice, index) => (
                <>
                  <dt id={practice.id} onClick={e => handleDetailedList(e)}>
                    <div className="icon">{detailItem === practice.id ? <MdArrowDropDown /> : <MdArrowRight />}</div> 
                    {practice.text}
                  </dt>
                  <dd className={detailItem === practice.id ? "show" : "hide"}>
                    {practice.subText}
                  </dd>
                </>
              ))}
            </dl>
          </div>
       
        <div className="anchor" id="actuMensuelle" style={{marginTop: "745px"}}></div>
        <h3>Les ateliers de sensibilisation à la RSE</h3>
        <Suspense fallback="">
          <Carousel 
            selectedEvent={selectedEvent}
            setSelectedEvent={setSelectedEvent} 
            scrollYPosition={calendarScrollPosition}
            />
        </Suspense>

        </div>
        </section>
       
        <section className="ideas">
          <div className="anchor" id="des_idees_pour_demarrer" style={{marginTop: "25px"}}></div>
          <h3 style={{margin: "100px auto"}}>Des actions locales pour améliorer mes pratiques</h3>
          <div className="grids">
            <div className="top">
            {localActions.filter(action => parseInt(action.id) < 4 ).map(action => (
                <div className="row">
                  <div className="info">
                    <p>{action.name}</p> 
                    <p>Contact : {action.contact}</p> 
                    <a href={`mailto:${action.mail.replace(' ', '')}`} className='mail'>{action.mail}</a>
                    <a href={`tel:${action.contact.replace(' ', '')}`} className='tel'>{action.tel}</a>
                  </div>
                  <a className="img" href={action.logo.url} target="blank">
                  <img src={action.logo.img} alt="" />
                  </a>
                </div>
              ))}
            </div>
            <div className="bottom">
            {localActions.filter(action => parseInt(action.id) >= 4 ).map(action => (
                <div className="row">
                  <div className="info">
                    <p>{action.name}</p> 
                    <p>Contact : {action.contact}</p> 
                    <a href={`mailto:${action.mail.replace(' ', '')}`} className='mail'>{action.mail}</a>
                    <a href={`tel:${action.tel.replace(' ', '')}`} className='tel'>{action.tel}</a>
                  </div>
                  <a className="img" href={action.logo.url} target="blank">
                  <img src={action.logo.img} alt="" />
                  </a>
                </div>
              ))}
            </div>
              
          </div>

          <div className="anchor" id="mon_bilan_carbone"></div> 
          
          <section className="bilanCarbone">

            <h4>Un bilan carbone pour mesurer mon impact environnemental</h4>

            <div className="bloc" ref={secondBlocRef}> 
              <div className="imgBox">
                <img src="./Assets/vadim-sherbakov-NQSWvyVRIJk-unsplash.jpg" alt="" title="Photo de Vadim Sherbakov sur Unsplash" />
              </div>
              <div className="right">
                <Suspense fallback="">
                 <AnimatedText
                   text="Une comptabilité carbone précise| permet aux entreprises de savoir| où elles émettent et où concentrer| leurs efforts de réduction."
                   />
                </Suspense>
              </div>
            </div>
           
            <div className="bilan_carbone_blocs">

              <h4>Un bilan carbone, pourquoi faire ?</h4>

              <div className="blocs">
                {carbonFootprintElement.map(elem => (
                  <Suspense fallback="">
                    <SlideInDiv icon={elem.icon} label={elem.text} />
                  </Suspense>
                ))}
              </div>
        
              <h4>Comment faire ?</h4>

              <div className="suggestions">
                <a target="blank" href="https://bigmedia.bpifrance.fr/nos-dossiers/bilan-carboner-de-lentreprise-definition-obligations-etapes-de-calcul-0">Bilan Carbone® de l’entreprise : définition, obligations, étapes de calcul | Big média | S’inspirer, S’informer, S’engager (bpifrance.fr)</a>
                <a target="blank" href="https://www.economie.gouv.fr/cedef/bilan-carbone-entreprise">Comment établir le bilan carbone d’une entreprise ? | economie.gouv.fr</a>
              </div>

            </div>
          </section>
        </section>
        <div className="anchor" id="les_experts_regionaux_de_la_RSE" style={{marginTop: 0}}></div>
        <section className="expertsBFC">
          <h2 style={{margin: "100px auto 20px"}}>Action RSE NFC travaille en partenariat avec les <span style={{textTransform: "upperCase"}}>experts</span> de la RSE</h2>
          <p><strong>Conseil régional Bourgogne-Franche-Comté</strong> anime le réseau régional RSE qui a pour mission de proposer des services RSE et développement durable aux entreprises. Il organise, en appui avec les partenaires du réseau régional RSE, Les Trophées de la RSE. Il déploie la COP régionale Bourgogne-Franche-Comté, notamment le volet 4 « Mieux produire » et apporte un soutien financier aux démarches RSE des entreprises. <a href="https://www.calameo.com/read/003030380d1506eeb6e70">https://www.calameo.com/</a> et <a href="https://www.cop-bfc-versdemain.fr/">https://www.cop-bfc-versdemain.fr/</a></p>
          <p className='subText' style={{margin: "20px 0", fontStyle: "italic"}}>Dans ce réseau, des experts :</p>
          <div className="grid_4">
            {rseExperts.map((expert, index) => (

              <div className={`row ${(index % 2 == 0) ? 'bckg_white' : ''}`} key={expert.id}>
                <div className="imgBox">
                  <a target='blank' href={expert.url}>
                  <Suspense fallback="">
                   <FadeInImg src={expert.img}/>
                  </Suspense>
                  </a>
                </div>
                <Suspense fallback="">
                  <FadeInText 
                    strong={expert.strong}
                    text={expert.text}
                  />
                </Suspense>
            </div>
            ))}
           
          
            <p style={{gridColumn: "1/3", fontStyle: "italic", marginTop: "50px"}}>Action RSE Nord Franche-Comté travaille également avec des consultants privés experts de la RSE : <a target="blank" href="https://www.koshi.cc/">Le cabinet Koshi</a>, le collectif <a target="blank" href="https://angievizcardo.com/">Vizcardo</a> | <a target="blank" href="https://www.shareandmove.fr/">Chippeaux</a>, <a target="blank" href="https://rse25.fr/">RSE25</a></p>

          </div>
        </section>

        <div id="normes_et_labels" className="anchor"></div>


        <section className="normesEtLabelRSE" style={{marginTop: "-35px"}}> 
          <h2>Des normes et des labels RSE</h2>
          <div className="normes">
          <p style={{width: "80%", margin: "0 auto"}}><strong>Une norme RSE</strong> est un cadre de référence qui établit des lignes directrices et des critères pour aider les entreprises à intégrer des préoccupations sociales, environnementales et économiques dans leurs activités et leur stratégie. Les normes RSE encouragent les entreprises à adopter des pratiques durables et éthiques, en tenant compte des impacts de leurs activités sur la société et l'environnement.<br/><br/>

          <strong>Un label RSE</strong> est une certification officielle qui signe l'engagement de l'entreprise dans une démarche de développement durable et de responsabilité sociétale. Les labels sont attribués par des organismes indépendants et reconnus qui peuvent aider les entreprises à mettre en valeur leurs engagements auprès de leurs parties prenantes, comme les clients, les investisseurs ou les employés.<br/><br/>

          <strong>En résumé</strong>, la norme RSE fournit un cadre pour les bonnes pratiques, tandis que le label RSE est une reconnaissance formelle de la conformité à ces normes.</p>
          <div className="anchor" id="les_normes_RSE" style={{marginTop: "-65px"}}></div>
          <h3>Les normes</h3>
          <div className="grid_5">
            <ul>
              <li><a target="blank" href="https://www.iso.org/fr/iso-26000-social-responsibility.html"><PiSealCheckFill /><div className="text"><strong>Responsabilité sociétale - ISO 26000</strong><p>guide les entreprises dans la mise en place de leur politique RSE</p></div></a></li>
              <li><a target="blank" href="https://www.iso.org/fr/iso-14001-environmental-management.html"><PiSealCheckFill /><div className="text"><strong>Management environnemental - ISO 14001</strong><p>définit les exigences d’un système de management environnemental (SME)</p></div> </a></li>
              <li><a target="blank" href="https://www.iso.org/fr/iso-50001-energy-management.html"><PiSealCheckFill /><div className="text"><strong>Management de l’énergie - ISO 50001</strong><p>propose des modalités pratiques pour mettre en œuvre un politique de réduction de la consommation d’énergie</p></div></a></li>
              <li><a target="blank" href="https://www.iso.org/fr/standard/63787.html"><PiSealCheckFill /><div className="text"><strong>Système de management de la santé et de la sécurité au travail - ISO 45001</strong></div></a></li>
              <li><a target="blank" href="https://www.iso.org/fr/standard/27001"><PiSealCheckFill /><div className="text"><strong>Système de management de la sécurité de l’information - ISO 27001</strong></div></a></li>
            </ul>
            <a target="blank" href="https://www.iso.org/fr/publication/PUB100259.html">
              <img src="https://action-rse.fr/Assets/afficherAfnor.PNG" alt="" />
            </a>
           </div>
          </div>
          <div className="labels">
          <div className="anchor" id="les_labels" style={{marginTop: "-65px"}}></div>

            <h3>Quelques grands types de labels RSE</h3>
            <div className="grid_6">
              <div className="labels_col">
                <h4>Des labels généralistes</h4>
                {labels.filter(label => label.type === "generalist").map(label => {
                  return <Label {...label} />
                })}
              </div>
              <div className="labels_col">
                <h4>Des labels territoriaux</h4>
                {labels.filter(label => label.type === "territorial").map(label => {
                  return <Label {...label} />
                })}
              </div>
              <div className="labels_col">
                <h4>Des labels thématiques</h4>
                {labels.filter(label => label.type === "thematic").map(label => {
                  return <Label {...label} />
                })}
              </div>
              <div className="labels_col">
                <h4>Des labels sectoriels</h4>
                {labels.filter(label => label.type === "sectorial").map(label => {
                  return <Label {...label} />
                })}
              
              </div>
            </div>
          </div>
        </section>

        <div id="ateliers_RSE" className="anchor" style={{marginTop: "125px"}}></div>

        <section className="events">
        <div className="anchor" id="les_ateliers_RSE" style={{marginTop: "85px"}}></div>
          <h2>L’actualité du pôle Action RSE Nord Franche-Comté</h2>

          <p style={{width: "80%", margin: "50px auto 80px"}}>Les ateliers Action RSE NFC sont destinés aux chefs d’entreprises, aux responsables du développement durable et de la RSE, aux managers, aux élus de CSE… et à tout salarié concerné par les sujets de la RSE. <br/><br/>
          Pour participer à un atelier, <Link to="/#contact">contactez-nous</Link>
          </p>

          

        <Suspense fallback="">
          <RSECalendar
          selectedEvent={selectedEvent}
          setSelectedEvent={setSelectedEvent} 
          calendarRef={calendarRef}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          />
        </Suspense>

        <Suspense fallback="">
          <BouncingButton 
          text="Voir les événements passés"
          url={"/archives"}
          />
        </Suspense>
    
        </section>
    
        <section id="contact" className="contact">
          <h3>Pour nous contacter</h3>

          <Suspense fallback="">
           <InteractivCard />
          </Suspense>
          
        </section>
     
     </div>
    </>
  )
}
