import React, {useEffect, useState, useRef, useContext} from 'react';
import { useForm } from 'react-hook-form';
import { DeviceWidthContext } from '../../context/deviceWidthContext';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import {v4 as uuidv4} from 'uuid'
import { doc, setDoc, addDoc, updateDoc, deleteDoc, collection, getDocs } from "firebase/firestore";
import {ref, uploadBytes, deleteObject, getDownloadURL, getStorage, uploadBytesResumable } from "firebase/storage"
import {db, storage} from '../../firebase-config'
import DatePicker from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import { ImBin } from "react-icons/im";
import { format } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import AddPictures from './AddPictures';
import AddFileButton from './AddFileButton';
import { FaCircleMinus } from "react-icons/fa6";
import { FaFilePdf } from "react-icons/fa6";
import { FaFileLines } from "react-icons/fa6";
import { PiSealCheckDuotone } from "react-icons/pi";
import { BiSolidMessageSquareError } from "react-icons/bi";
import AddCategory from './AddCategory';

export default function AddEventForm({preloadedData}) {

    const { toggleModal, modalOpen } = useContext(DeviceWidthContext)
    
    registerLocale('fr', fr);
    setDefaultLocale('fr');

    const year = new Date().toLocaleString().slice(6, 10)

    let today = new Date()

    const [startHour, setStartHour] = useState('');
    const [endHour, setEndHour] = useState('');
    const [selectedStartHour, setSelectedStartHour] = useState('');
    const [selectedEndHour, setSelectedEndHour] = useState('');
    const [selectedEventDate, setSelectedEventDate] = useState('');
    const [error, setError] = useState('');
    const [eventDate, setEventDate] = useState('')
    const [images, setImages] = useState([]);
    const maxLenght = 4;
    const imageTypeRegex = /image\/(png|jpg|jpeg|webp)/gm;
    const [validImageFiles, setImageFiles] = useState([]);
    const [files, setFiles] = useState([]);
    const [progressPercent, setProgressPercent] = useState(0)
    const [animatorFieldNumber, setAnimatorFieldNumber] = useState(1)
    const [submitStatus, setSubmitStatus] = useState("")
    const [selectedOptions, setSelectedOptions] = useState([])

    const id = preloadedData ? preloadedData.id : uuidv4()

    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        mode: 'onTouched',
      });

    const [imgs, setImgs] = useState([]);
      
    const handlePictures = async (e) => {
      const { files } = e.target;
      const validImageFiles = [];
      for (let i = 0; i < files.length; i++) {
        const newImage = e.target.files[i];
        newImage["id"] = e.target.id;
        setImages((prevState) => [...prevState, newImage]);

        const file = files[i];
        if (file.type.match(imageTypeRegex)) {
          validImageFiles.push(file);
        }
        if (validImageFiles.length > maxLenght) {
          validImageFiles.pop(file);
          alert("Vous ne pouvez pas ajouter plus de 4 images");
      }

      console.log(validImageFiles)

         

      }
      if (validImageFiles.length) {
        setImageFiles(validImageFiles);
        return;
      }
      else {
        alert("Le type d'images sélectionné n'est pas valide");
      }
    };


      const handleUpload = () => {
        const promises = [];
        console.log('imgMap');
        validImageFiles.map((validFile) => {

          const metadata = {
            contentType: 'image/jpeg'
          };

          const storageRef = ref(storage, `articles/${year}/files/${validFile.name}`);
          console.log('Launch');
          
          if((imgs && !imgs.some(img => img.name === validFile.name))) {
            const uploadTask = uploadBytesResumable(storageRef, validFile, metadata)
            promises.push(uploadTask);
            uploadTask.on(
              "state_changed",
              (snapshot) => {
              
              },
              (error) => {
                console.log(error);
              },
              async () => { 
                
                getDownloadURL(storageRef).then((urls, name) => {
                  setImgs((prevState) => [...prevState, {name: validFile.name, src: urls, id:validFile.id}]);
                });
                
              }
            );
          }
        
        } ); 
        
    
        Promise.all(promises)
         
          .catch((err) => console.log(err));
          
      };

      const removeImg = (name, e) => {
  
        const imgRef = ref(storage, `articles/${year}/files/${name}`);
     
        deleteObject(imgRef).then(() => {
          console.log('success');
          }).catch((error) => {
            console.log('error');
            });
        let newArr = imgs.filter(img => img.name !== name)
            setImgs(newArr)
        }

      const removeFile = (name, e) => {
  
        const fileRef = ref(storage, `articles/${year}/files/${name}`);
     
        deleteObject(fileRef).then(() => {
          console.log('success');
          }).catch((error) => {
            console.log('error');
            });
        let newArr = files.filter(file => file.name !== name)
            setFiles(newArr)
        }

    useEffect(() => {
          handleUpload()
        }, [validImageFiles])


        console.log(imgs);
        

    const handleAnimatorFieldNumber = async () => {
      if(animatorFieldNumber < 3) {
          setAnimatorFieldNumber(animatorFieldNumber+1)     
      }
      else {
         alert("Vous ne pouvez pas ajouter plus de trois animateurs")
       }
      }
    

    const handleDelete = async () => {
        const ref = doc(db, "calendrier", id.replace('_', ''));

        if(window.confirm("Etes-vous sûr ? \n Cette action est irréversible") == true) {
            let docRef = await deleteDoc(ref)
            if(modalOpen) {
              toggleModal()
            }
            window.location.reload()
        }
    }

    const formRef = useRef()

    const handleStartHourChange = (date) => {
      console.log(date);
      
            if((endHour && date.toLocaleString().slice(10, 16) > endHour.toLocaleString().slice(10, 16))) {
                alert("Veuillez choisir une date antérieure à la date de début de l'événément")
            } else {
                setStartHour(date);
            }

    };

    const handleEndHourChange = (date) => {
      if((startHour && date.toLocaleString().slice(10, 16) < startHour.toLocaleString().slice(10, 16))) {
          alert("Veuillez choisir une date ultérieure à la date de début de l'événément")
      } else {
          setEndHour(date);
      }

  };

  const handleEventDate = (date) => {
    setEventDate(date)

  };

  const loadAndCleanEventCategory= async (eventId, newCategories) => {
  
      console.log(eventId);
      
      let eventCategories = []
  
      const ref = collection(db, 'calendrier', eventId, 'categories')
  
      const querySnapshot = await getDocs(ref)
      querySnapshot.forEach((doc) => {
        eventCategories.push(doc.id)
      })
  
      console.log(eventCategories);

      let deletedCategories = []

      deletedCategories = eventCategories.filter(
        oldCategory => !newCategories.some(newCategory => newCategory.id === oldCategory)
      );
      
      console.log(deletedCategories);
      console.log(newCategories);
      
      deletedCategories.forEach(category => {
        const ref = doc(db, 'calendrier', eventId, 'categories', category)
        let deletedDoc = deleteDoc(ref)
      })
    }

    
    const onSubmit = async data => {

      let removeDefaultCategory = selectedOptions.filter(category => category.category !== 'default')

      if(preloadedData) {
        if(removeDefaultCategory.length > 0) {
          loadAndCleanEventCategory(preloadedData.id.replace('_', ''), removeDefaultCategory)
        } else {
          loadAndCleanEventCategory(preloadedData.id.replace('_', ''), selectedOptions)
        }
      }

        
        let eventInfo = {}
       
        console.log(data);
        
        if(eventDate !== null || eventDate !== undefined) {
          
          eventInfo = {
              ID: id.startsWith('_') ? id : `_${id}`,
              title: data.title,
              start: selectedEventDate !== "" ? (selectedEventDate.toLocaleString().slice(0, 10) + selectedStartHour.toLocaleString().slice(10, 16)) : "",
              end: selectedEventDate !== "" ? (selectedEventDate.toLocaleString().slice(0, 10) + selectedEndHour.toLocaleString().slice(10, 16)) : "",
              descriptif: data.descriptif ? data.descriptif : "",
              animateurs: [
                {
                  nom: data.animator1_Name ? data.animator1_Name : "",
                  url: data.animator1_Url ? data.animator1_Url : "",
                  logo: imgs.some(img => img.id === "animator_1_logo") ? imgs.find(img => img.id === "animator_1_logo") : (preloadedData && preloadedData.animateurs[0]) ? preloadedData.animateurs[0].logo : ""
                },
                {
                  nom: data.animator2_Name ? data.animator2_Name : "",
                  url: data.animator2_Url ? data.animator2_Url : "",
                  logo: imgs.some(img => img.id === "animator_2_logo") ? imgs.find(img => img.id === "animator_2_logo") : (preloadedData && preloadedData.animateurs[1]) ? preloadedData.animateurs[1].logo : ""
                },
                {
                  nom: data.animator3_Name ? data.animator3_Name : "",
                  url: data.animator3_Url ? data.animator3_Url : "",
                  logo: imgs.some(img => img.id === "animator_3_logo") ? imgs.find(img => img.id === "animator_3_logo") : (preloadedData && preloadedData.animateurs[2]) ? preloadedData.animateurs[2].logo : ""
                },
              ],
              lieu: {
                nom: (data.placeName && data.placeName !== " ") ? data.placeName : preloadedData ? preloadedData.lieu.nom : "",
                url: data.placeUrl ? data.placeUrl : preloadedData ? preloadedData.lieu.url : "",
                logo: imgs.some(img => img.id === "place_logo") ? imgs.find(img => img.id === "place_logo") : preloadedData ? preloadedData.lieu.logo : ""
              },
              fichiers: files
      }
        console.log(eventInfo);
        
        } else {
            setError("Veuillez choisir une date de début")
        } 
        
        try {

          let formatId = id.replace('_', '')
            const ref = doc(db, "calendrier", formatId);

            selectedOptions.forEach(option => {
              let refSubCol = doc(db, "calendrier", formatId, 'categories', option.id);
              let pushSubColDoc = setDoc(refSubCol, option)
            })
            
            if(!preloadedData) {
                let docRef = await setDoc(ref, eventInfo)
                setSubmitStatus({
                  status: "success_add",
                  message : "L'événement à bien été enregistré !",
                })
            } else {
              const [day, month, year] = eventInfo.start.split('/');
              const date = new Date(`${month}-${day}-${year}`)
              if(date > today) {
                let docRef = await updateDoc(ref, eventInfo)
              } else {
                let docRef = await updateDoc(ref, eventInfo)
              }
                setSubmitStatus({
                  status: "success_edit",
                  message : "L'événement à bien été modifié !",
                })
            
            }
            reset()
            setFiles([])
            setImgs([])
            setProgressPercent(0)
            setEventDate(null)
            setStartHour(null)
            setEndHour(null)
            setAnimatorFieldNumber(1)

        } catch (err) {
            console.log("Erreur" + err);
            setSubmitStatus({
              status: "error",
              message : "Une erreur s'est produite, veuillez réessayer ultérieurement",
            })
            
        }

        window.scrollTo(0, 0)
    }
  
    console.log(animatorFieldNumber);
    console.log(selectedEventDate);
    console.log(selectedStartHour);  

    useEffect(() => {
      setImgs([])
        reset(preloadedData)
        if(preloadedData) {

         preloadedData.animateurs.map(animator => {
            if(!imgs.includes(animator.logo.nom)) {
              setImgs(prev =>  [animator.logo, ...prev])
            }
          })
          if(preloadedData.lieu.nom &&!imgs.includes(preloadedData.lieu.logo.nom)) {
            setImgs(prev =>  [preloadedData.lieu.logo, ...prev])
          }
          if((preloadedData && preloadedData.fichiers.length > 0)) {
            setFiles([])
            setFiles(preloadedData.fichiers)
          }
          if(preloadedData.animateurs.filter(animator => animator.nom !== "").length > 0) {  
            setAnimatorFieldNumber(preloadedData.animateurs.filter(animator => animator.nom !== "").length)
          } else {
            setAnimatorFieldNumber(1)
          }

          for(let i = 0; i < preloadedData.animateurs.length; i++) {
            // inputRefs.current[i].defaultValue= preloadedData.animateurs[i].nom
          } 

        }
    
        
    }, [preloadedData])

    useEffect(() => {
      if(endHour !== "") {
        setSelectedEndHour(endHour)
      } else if(preloadedData) {
        setSelectedEndHour(preloadedData.end)
      }

      if(startHour !== "") {
        setSelectedStartHour(startHour)
      } else if(preloadedData) {
        setSelectedStartHour(preloadedData.start)
      }

      if(eventDate !== "") {
        setSelectedEventDate(eventDate)
      } else if(preloadedData) {
        setSelectedEventDate(preloadedData.start)
      }

      if(preloadedData) {
        setSelectedOptions(preloadedData.categories)
      } else {
        setSelectedOptions([{id: uuidv4(), category: "default"}])
      }
    }, [preloadedData, startHour, endHour, eventDate])

    
    
           

    console.log(selectedOptions); 
    console.log(preloadedData); 

    return (
        
        <div className="addEventForm">

            <h2>{preloadedData ? "Modifer l'article" : "Ajouter un article"}</h2>

            {submitStatus !== "" ? 

              <div className={`submitStatus ${submitStatus.status}`}>
                <p>{submitStatus.message}</p>
                {submitStatus.status !== "error" ? <PiSealCheckDuotone /> : <BiSolidMessageSquareError />}
              </div>

            :
            
            <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                {error &&
                <p className='validationText'>{error}</p>
                }
           
            <input
                    id="title"
                    name="title"
                    placeholder="Titre de l'article"
                    {...register('title', { required: 'Le titre de l\'article est requis' })}
            />

           <AddCategory selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions}/>

           {errors.title && <small>{errors.title.message}</small>}
           {preloadedData ? 
            <>
             {Array.from({ length: animatorFieldNumber}, (_, number) => (
                <div className="addInfo">
                    <div className="info">
                      <input
                              type="text"
                              id={`animator_${number+1}_name`}
                              name={`animator_${number+1}Name`}
                              defaultValue={preloadedData.animateurs[number] ? preloadedData.animateurs[number].nom : ""}
                              placeholder="Animé par"
                              {...register(`animator${number+1}_Name`)}
                              />
                      <input
                              type="text"
                              id={`animator_${number+1}_url`}
                              name={`animator_${number+1}Url`}
                              defaultValue={preloadedData.animateurs[number] ? preloadedData.animateurs[number].url : ""}
                              placeholder="Lien du site de l'animateur"
                              {...register(`animator${number+1}_Url`)}
                              />
                    </div>
                    <AddPictures 
                        label={`Ajouter le logo de l\'animateur n° ` + (number+1)}
                        handlePictures={handlePictures}
                        id={`animator_${number+1}_logo`}
                        imgs={imgs}
                        removeImg={removeImg}
                        />
                </div>
                )
              )}
            </>
             :
            <div className="animators">
            {Array.from({ length: animatorFieldNumber}, (_, number) => (
              <div className="addInfo">
                  <div className="info">
                    <input
                            type="text"
                            id={`animator_${number+1}_name`}
                            name={`animator${number+1}_Name`}
                            placeholder="Animé par"
                            {...register(`animator${number+1}_Name`)}
                            />
                    <input
                            type="text"
                            id={`animator_${number+1}_url`}
                            name={`animator${number+1}_Url`}
                            placeholder="Lien du site de l'animateur"
                            {...register(`animator${number+1}_Url`)}
                            />
                  </div>
                  <AddPictures 
                      label={`Ajouter le logo de l\'animateur n° ` + (number+1)}
                      handlePictures={handlePictures}
                      id={`animator_` + (number+1) + `_logo`}
                      imgs={imgs}
                      removeImg={removeImg}
                      />
              </div>
            ))}
            
            </div>
            
           } 
            
           <button 
            type="button" 
            onClick={() => handleAnimatorFieldNumber()}>
              Ajouter un animateur
            </button>
            <div className="addInfo">
              <div className="info">
                <input
                type="text"
                id="place_name"
                name="placeName"
                defaultValue={preloadedData ? preloadedData.lieu.nom : ""}
                placeholder='Lieu'
                {...register('placeName')}
                />
                {errors. placeName && <small>{errors. placeName.message}</small>}
                <input
                type="text"
                id="place_url"
                name=" placeUrl"
                defaultValue={preloadedData ? preloadedData.lieu.url : ""}
                placeholder="Lien du lieu"
                {...register('placeUrl')}
                />
                </div>
                <AddPictures 
                label={'Ajouter le logo du lieu'}
                handlePictures={handlePictures}
                id={'place_logo'}
                imgs={imgs}
                removeImg={removeImg}
                
                />
            </div>
            <div className='pickers'>
                <div className="date">
                    <label htmlFor="email">Date de l'événement :</label>
                    <DatePicker
                    name="eventDate"
                    selected={selectedEventDate}
                    onChange={handleEventDate}
                    showTimeSelect={false}
                    dateFormat="dd/MM/yyyy"
                    timeIntervals={15}
                    locale="fr" 
                    autoComplete="off"
                    />
                </div>
                <div className="heureDeDebut">
                    <label htmlFor="email">Heure de début:</label>
                    <DatePicker
                    name="start"
                    selected={selectedStartHour}
                    onChange={handleStartHourChange}
                    showTimeSelect
                    showTimeSelectOnly
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="Heure"
                    dateFormat="HH:mm" 
                    locale="fr"
                    autoComplete="off"
                    />
                </div>
                <div className="heureDeFin">
                    <label htmlFor="email">Heure de fin :</label>
                    <DatePicker
                    name="end"
                    selected={selectedEndHour}
                    onChange={handleEndHourChange}
                    showTimeSelect
                    showTimeSelectOnly  
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="Heure"
                    dateFormat="HH:mm" 
                    locale="fr"
                    autoComplete="off"
                    />
                </div>
            </div>

            <textarea placeholder="Descriptif" rows="5" {...register('descriptif')} name="descriptif" id="" defaultValue={preloadedData ? preloadedData.descriptif : ""}></textarea>
            {files.length > 0 &&
                  <>
                  <h5>Fichiers additionnels :</h5>
                  <div className="fichiers">

                  {files.map(file => (
                    file.type.includes("image") ? 
                    <div className="file">
                       <div className="remove">
                        <FaCircleMinus onClick={() => removeFile(file.name)} />
                       </div>
                      <img src={file.url} onClick={() => window.open(file.url, '_blank')}></img>
                      <div className="bkgd"  style={{backgroundImage: `url(${file.url})`}}></div>
                    </div>
                    : file.type.includes("pdf") ? 
                    <div className="file">
                      <div className="remove">
                         <FaCircleMinus onClick={() => removeFile(file.name)} />
                      </div>
                    <a href={file.url} target='_blank'>
                      <FaFilePdf />
                    </a>
                    </div>
                    : <div className="file">
                        <div className="remove">
                          <FaCircleMinus onClick={() => removeFile(file.name)} />
                        </div>
                        <a href={file.url} target='_blank'>
                        <FaFileLines />
                      </a>
                     </div>
                  ))
                 }
                  </div>
                 </>
            }
            <AddFileButton setFiles={setFiles} files={files} progressPercent={progressPercent} setProgressPercent={setProgressPercent}/>
            <button type="submit">{preloadedData ? "Modifier" : "Soumettre"}</button>
            {preloadedData && 
             <button type="button" onClick={handleDelete}><ImBin /> Supprimer l'événement</button>
            }
        </form>
        }
        </div>
    
    )

  }