export const GetLabelsData = () => { 
    return new Promise((resolve, reject) => {      
          const labels = [
            {
                id: '1',
                type: "generalist",
                name: "LUCIE",
                logo: "https://action-rse.fr/Assets/logo-certification-lucie-label.png",
                url: "https://www.labellucie.com/"
            },
            {
                id: '2',
                type: "generalist",
                name: "Engagés RSE",
                logo: "https://action-rse.fr/Assets/rse_initial_contour_Afnor.jpg",
                url: "https://certification.afnor.org/developpement-durable-rse/label-engage-rse"
            },
            {
                id: '3',
                type: "generalist",
                name: "Responsability Europe",
                logo: "/Assets/label_responsibility_europe.png",
                url: "https://www.responsibility-europe.org/"
            },
            {
                id: '4',
                type: "generalist",
                name: "EcoVadis",
                logo: "/Assets/ecovadis.png",
                url: "https://ecovadis.com/fr/"
            },
               
            {
                id: '5',
                type: "territorial",
                name: "Empl’itude",
                logo: "https://action-rse.fr/Assets/logo-EMPLITUDE-TdB-transparent.png",
                url: "https://www.label-emplitude.fr/club.aspx"
            },
            {
                id: '6',
                type: "territorial",
                name: "Alsace Excellence",
                logo: "https://action-rse.fr/Assets/xlogo-label-alsace-excellence.png.pagespeed.ic.q8l-w_RfxW.png",
                url: "https://www.excellence.alsace/"
            },
            {
                id: '7',
                type: "territorial",
                name: "Lyon ville équitable et durable",
                logo: "https://action-rse.fr/Assets/logo_label_Lyon_ville_equitable.jpg",
                url: "https://mairie1.lyon.fr/le-label-lyon-ville-equitable-et-durable"
            },
               
            {
                id: '8',
                type: "thematic",
                name: "Label Numérique Responsable",
                logo: "https://action-rse.fr/Assets/LNR-blanc-label-nr.webp",
                url: "https://label-nr.fr/"
            },
            {
                id: '9',
                type: "thematic",
                name: "Label Egalité professionnelle",
                logo: "https://action-rse.fr/Assets/labels-egalite-afnor.png",
                url: "https://travail-emploi.gouv.fr/droit-du-travail/egalite-professionnelle-discrimination-et-harcelement/article/le-label-egalite-professionnelle-un-outil-pour-favoriser-l-egalite-femmes"
            },
            {
                id: '10',
                type: "thematic",
                name: "Label EnVol",
                logo: "https://action-rse.fr/Assets/logo-EnVol-envol-entreprise.png",
                url: "https://www.envol-entreprise.fr/"
            },
            {
                id: '11',
                type: "thematic",
                name: "Label Entreprise engagée pour la nature",
                logo: "https://action-rse.fr/Assets/TAMPONS_TEN_GENERIQUE.png",
                url: "https://engagespourlanature.ofb.fr/entreprises"
            },
               
            {
                id: '12',
                type: "sectorial",
                name: "Label Responsabilité et santé",
                logo: "https://action-rse.fr/Assets/Logo-RS-CMJN.png",
                url: "https://www.snadom.org/label-rse/"
            },
            {
                id: '13',
                type: "sectorial",
                name: "Label Prestadd (Synaps)",
                logo: "https://action-rse.fr/Assets/labels-egalite-afnor.png",
                url: "https://www.prestadd.fr/"
            },
            {
                id: '14',
                type: "sectorial",
                name: "Mon Coiffeur s’engage",
                logo: "https://action-rse.fr/Assets/coiffeur-engage.png",
                url: "https://moncoiffeursengage.com/"
            },
          ]
          setTimeout(() => resolve(labels), 1000)
        }) 
    
     }