import React, {useEffect, useState, useContext} from 'react';
import { useForm } from 'react-hook-form';
import SignIn from '../components/SignIn';
import SignUp from '../components/SignUp';
import ResetPassword from '../components/ResetPassword';
import { UserContext, userContext } from '../context/userContext';

export default function LogInForm() {

    const { register, handleSubmit, formState: { errors } } = useForm();
    const [signUp, setSignUp] = useState('signIn')
    const [forgotPassword, setForgotPassword] = useState(false)
    const [type, setType] = useState("password")
    const [confirmType, setConfirmType] = useState("password")

   

    const handleType = () => {

      if(type === "password") {
          setType('text')
      } else {
          setType("password")
      }
  }

  const handleConfirmType = () => {

      if(confirmType === "password") {
        setConfirmType('text')
    } else {
        setConfirmType("password")
    }
  }

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])


  return (
    <div className='loginPage'>
      <h1>Se connecter</h1>

      <div className="loginChoice">
        <span onClick={() => setSignUp('signIn')}>s'identifier</span>
        <span>|</span>
        <span onClick={() => setSignUp('signUp')}>s'inscrire</span>
      </div>
            <div className="signInBody" >
                {signUp === 'signUp' ?
                <SignUp type={type}  setType={setType} confirmType={confirmType}  setConfirmType={setConfirmType} handleType={handleType} handleConfirmType={handleConfirmType}/> 
                : signUp === 'forgotPwd' ? 
                <ResetPassword setForgotPassword={setSignUp}/> 
                : signUp === 'signIn' ? 
                <SignIn type={type} signUp={signUp} setSignUp={setSignUp} setType={setType} /> 
                : ""
                } 
            </div>
    </div>
  )
}
