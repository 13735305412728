import React, {useEffect, useState} from 'react'
import { HashLink as Link } from 'react-router-hash-link';

export default function DropDown({ subMenu, index }) {


    
  return (
    <ul className="subMenu">
        {subMenu.map(item => {
         return <li key={index + item.titre}><Link to={`/${item.url}`}>{item.titre}</Link></li>
        })}
    </ul>
  )
}
