import React, { useState, useRef, useEffect, useContext } from 'react'
import {MdUploadFile} from 'react-icons/md' 
import {RxCross2} from 'react-icons/rx' 
import {useForm} from 'react-hook-form';
import { collection, setDoc, doc, addDoc, getDocs, where, query, serverTimestamp} from "firebase/firestore";
import {ref, uploadBytes, getDownloadURL, getStorage, uploadBytesResumable } from "firebase/storage"
import { db, storage } from "../../firebase-config";
import { UserContext, userContext } from '../../context/userContext';
import {v4 as uuidv4} from 'uuid'
import { FaUpload } from "react-icons/fa6";

export default function AddFileButton({ accessName, setFiles, files, progressPercent, setProgressPercent}) {


  const [open, setOpen] = useState(false)
 
  const [dragActive, setDragActive] = useState(false);







const handleUpload = (files) => {
  if (!files) return;

  Array.from(files).forEach((file) => {
    
    const storageRef = ref(storage, `files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgressPercent(progress);
      },
      (error) => {
        console.error(error);
      },
      () => {
        getDownloadURL(storageRef).then((url) => {
          setFiles(files => [
            ...files,
            {
            name: file.name,
            type: file.type,
            url: url
          }]);
        });
      }
    );
  });
};

const handleFileChange = (e) => {
  if (e.target.files) {
    handleUpload(e.target.files); // Gérer plusieurs fichiers
  }



};

const handleDrop = (e) => {
  e.preventDefault();
  e.stopPropagation();
  setDragActive(false);

  if (e.dataTransfer.files) {
    handleUpload(e.dataTransfer.files); 
  }
};

const handleDrag = (e) => {
  e.preventDefault();
  e.stopPropagation();
  if (e.type === "dragenter" || e.type === "dragover") {
    setDragActive(true);
  } else if (e.type === "dragleave") {
    setDragActive(false);
  }
};




// const onSubmit = async (data) => {
  
//   let access = [accessName]

//   await Promise.all(files.map(async (file) => {
//     let id = uuidv4()

//   if(data.access.length > 0) {

//     if(!data.access.includes('Tous')) {
//       let newArr = [...access, ...data.access]
//       access = newArr
//     } else {
//       access = ["Tous"]
//     }
//   }

//     let parentId = null 
//     if(currentFolderId !== undefined) {
//       parentId = currentFolderId
//     }
//     const path = [...currentFolder.path]
  
//     if(currentFolder !== ROOT_FOLDER) {
//       path.push({intitule: currentFolder.intitule, id: currentFolder.id})
//     }

//     if(currentFolder === null) return 
//       console.log(data);

//       try {
    
//         const ref = collection(db, "fichiers",);
//         await setDoc(doc(ref, id), {
//           id: id,
//           userId: currentUser.uid,
//           creer_par: userData.name + ' ' + userData.lastName,
//           intitule: file.name,
//           access: access,
//           date_de_creation: new Date().toLocaleString(),
//           folderId: currentFolder.id,
//           type: file.type,
//           url: file.url
//       }
//         )
//           console.log("success");
//           setOpen(false)
//           window.location.reload();
         
//         //  setSubmitSuccess(true)
//     }
//     catch(err) {
//         console.log(err)
//         // setSubmitSuccess(false
//     }
     
  
//   }));

// }


const addFileRef = useRef();

  return (
    <div className='addFileArea'>
     
              <label>Ajouter un fichier</label>
         
                    <div className={`add_file_box ${dragActive ? "drag-active" : ""}`} 
                      onDragEnter={handleDrag} 
                      onDragLeave={handleDrag} 
                      onDragOver={handleDrag} 
                      onDrop={handleDrop}>
                    <div className="upload"><span><FaUpload/></span> <span>Glissez et déposez un fichier ici ou cliquez pour sélectionner</span></div>
                    <input className="inputFile" type="file" onChange={handleFileChange} multiple />
                  </div>
                  <div className="progression">
                    <progress value={progressPercent} max="100"/>
                    <span>{progressPercent} %</span> 
                  </div>
    </div>
  )
}

