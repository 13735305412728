export const GetRSEExpertsData = () => { 
    return new Promise((resolve, reject) => {      
          const experts = [
            {
                id: '1',
                strong: "Agence Economique Régionale",
                text: 'accompagne le maintien et le développement de l\'activité économique et l\'emploi sur le territoire, promeut l\'attractivité du territoire, soutient et développe l\'innovation et la transition écologique.',
                url: "https://aer-bfc.com/",
                img: "Assets/thumbnail_image003.png",
            },
            {
                id: '2',
                strong: "Mouvement Français pour la Qualité",
                text: 'accompagne les organisations dans leur démarche qualité, sécurité, environnement et RSE. Avec le soutien de la Région Bourgogne Franche-Comté, le MFQ propose une offre d’accompagnement à la carte pour définir, mettre en place, évaluer et valoriser l’engagement RSE des organisations.',
                url: "https://mfq-bfcasso.fr/l-accompagnement-rse",
                img: "Assets/thumbnail_image005.jpg",
            },
            {
                id: '3',
                strong: "BPI France",
                text: 'finance et accompagne les entreprises dans leurs projets de développement, de transition écologique et énergétique, et d’innovation, en appui des politiques publiques conduites par l’Etat et les Régions.',
                url: "https://www.bpifrance.fr/nous-decouvrir/notre-mission",
                img: "Assets/bpiFrance.png",
            },
            {
                id: '4',
                strong: "ARACT",
                text: 'Agence Régional pour l’Amélioration des conditions de travail, impulse des projets d’amélioration des conditions de travail avec les acteurs d’entreprises et du territoire.',
                url: "https://www.anact.fr/bourgogne-franche-comte",
                img: "Assets/aract.jpg",
            },
            {
                id: '5',
                strong: "PRIAE",
                text: 'Pôle Ressources de l’Insertion par l’Activité Economique, promeut l’insertion par l’activité économique comme outil de la RSE pour les organisations.',
                url: "https://www.pole-iae-bfc.org/",
                img: "Assets/channels4_profile.jpg",
            },
            {
                id: '6',
                strong: "La Chambre de Commerce et d’Industrie de Bourgogne Franche-Comté",
                text: 'accompagne les entreprises dans leur transition écologique et énergétique en apportant une aide pour augmenter l’efficacité de l’utilisation de leurs ressources (eau, matières, énergie, déchets) et diminuer leur impact sur l’environnement.',
                url: "https://www.bourgognefranchecomte.cci.fr/developpement-durable",
                img: "Assets/Logo_cci.png",
            },
            {
                id: '7',
                strong: "La Chambre de Métiers et de l’Artisanat de Bourgogne Franche-Comté",
                text: 'intervient auprès des entreprises pour une optimisation des consommations d’énergies et la sensibilisation du chef d’entreprise sur les points de vigilance et les actions à mettre en œuvre pour réaliser des économies.',
                url: "https://www.artisanat-bfc.fr/article/connaitre-et-maitriser-mes-impacts",
                img: "Assets/cma.png",
            },
            {
                id: '8',
                strong: "RT2E",
                text: 'Réseau de la transition écologique et économique en Bourgogne-Franche-Comté accompagne techniquement ou financièrement les entreprises sur l’ensemble de leur projet en lien avec la transition énergétique. Il est animé par la Région BFC et l’ADEME est composé des conseillers en Transition Energétique et Ecologique des CCI, CMA, de l’AER et de la BPI.',
                url: "Assets/RT2E_complet%20entreprisesBFC.pdf",
                img: "Assets/RT2E.PNG",
            },
            {
                id: '9',
                strong: "Agence Régional pour la biodiversité",
                text: 'est aux cotés des entreprises volontaires pour les accompagner dans leur plan d’amélioration de leur impact sur la biodiversité.',
                url: "https://www.arb-bfc.fr/",
                img: "Assets/logo-arb-bfc.jfif",
            },
            {
                id: '10',
                strong: "AFNOR",
                text: 'groupe international qui conçoit des solutions fondées sur les normes volontaires pour les organisations et les personnes.',
                url: "https://www.afnor.org/le-groupe/qui-sommes-nous/",
                img: "Assets/afnor.png",
            },
            {
                id: '11',
                strong: "Agence Economique Régionale",
                text: 'accompagne le maintien et le développement de l\'activité économique et l\'emploi sur le territoire, promeut l\'attractivité du territoire, soutient et développe l\'innovation et la transition écologique.',
                url: "https://aer-bfc.com/",
                img: "Assets/thumbnail_image003.png",
            },
            {
                id: '12',
                strong: "ADEME",
                text: 'l’Agence de la transition écologique agit en faveur de la compétitivité des entreprises en la faisant rimer avec environnement.',
                url: "https://www.ademe.fr/direction-regionale/bourgogne-franche-comte/",
                img: "Assets/ademe-logo-2022-1.png",
            },
            {
                id: '13',
                strong: "LPO | Agir pour la biodiversité",
                text: 'Tous les secteurs d’activité sont concernés par la protection de la nature et chacun, salarié comme dirigeant, peut proposer la mise en place de mécénat au sein de son entreprises.',
                url: "https://www.lpo.fr/s-engager-a-nos-cotes/agir-avec-son-entreprise/mecenat",
                img: "Assets/lpo.jpeg",
            },
            {
                id: '14',
                strong: "Ecorse TP",
                text: 'facilite les innovation et les bonnes pratiques environnementales dans les travaux publics en rendant concrets les enjeux du développement durable et en développant ainsi la compétitivité des entreprises. C’est un  réseau d’entreprises, de collectivités et de laboratoires qui contribuent à l’aménagement durable du territoire dans une dynamique d’innovation et de préservation de l’environnement en Bourgogne – Franche-Comté.',
                url: "https://ecorse-tp.com/",
                img: "Assets/ecorce_tp_.jpg",
            },
            {
                id: '15',
                strong: "Halte aux discrimination",
                text: 'accompagne les entreprises dans la lutte contre toute forme de discrimination en entreprise par des actions de sensibilisation, des diagnostics, un accompagnement individuel et collectif, des échanges de bonnes pratiques RH « égalité des chances » et la valorisation d’entreprises au travers d’événements.',
                url: "https://halt-discrimination.org/",
                img: "Assets/logo-halte-discriminations.png",
            },
            {
                id: '16',
                strong: "FETE",
                text: 'Femme Egalité Emploi déploie des actions de lutte contre les violences sexistes et sexuelles en entreprise et auprès de publics variés (collectivités, acteurs de l’emploi et de la formation, lycées… ). L’association intervient en entreprise pour aider à établir un diagnostic de l’égalité professionnelle, pour accompagner le dialogue et la négociation, soutenir la mise en conformité avec la loi et, pourquoi pas, accompagner l’obtention du label égalité de l’AFNOR.',
                url: "https://www.fete-egalite.org/",
                img: "Assets/fete.png",
            },
          ]
          setTimeout(() => resolve(experts), 1000)
        }) 
    
     }