import React from 'react'
import AddEventForm from '../components/Events/AddEventForm'
import { TiArrowBack } from "react-icons/ti";
import { useNavigate } from 'react-router-dom';

export default function AddEvents() {

    const navigate = useNavigate();


  return (
    <div className='addEvents'>
      <button className="back" onClick={() => navigate('/account')}><TiArrowBack /> Retour</button>
      <AddEventForm />
    </div>
  )
}
